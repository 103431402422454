import {
  call,
  put,
  getContext,
} from 'redux-saga/effects';

import {
  fetchAddressSuggestionsInProgress,
  fetchAddressSuggestionsSuccess,
  fetchAddressSuggestionsError,
} from 'src/redux/customer/addressSuggestionsSlice';
import { SagaContextItem } from 'src/store/ReduxSagaContext';
import { BillingAddress } from 'src/types/customer/address';
import { createAddressCheckErrorResponse } from 'src/types/customer/AddressCheckErrorResponse';
import { PostalAddress } from 'src/types/customer/AddressType';
import { AddressValidationResponse } from 'src/types/customer/AddressValidationResponse';
import { isBadRequestStatus } from 'src/utils/errorStatusChecks';
import logErrorAndShowNotification from 'src/utils/logErrorAndShowNotification';
import serializeAddress from 'src/utils/mappers/serializeAddress';


export function* ValidateAddress(addressValidated: boolean, address: BillingAddress | PostalAddress | undefined) {
  try {
    if (addressValidated) {
      return true;
    } else {
      if (!address) {
        return false;
      }
      const {
        street,
        streetNumber,
        zipCode,
        city,
        countryCode
      } = address;
      const apiAddress = yield getContext(SagaContextItem.apiAddress);

      yield put(fetchAddressSuggestionsInProgress());
      const addresses: AddressValidationResponse = yield call(apiAddress.validateAddress, serializeAddress({
        street,
        streetNumber,
        zipCode,
        city,
        countryCode,
      }));

      const completeAddresses = addresses.completeAddresses;

      if (completeAddresses.length >= 1) {
        const addressMatch = completeAddresses[0].street === street
          && completeAddresses[0].streetNumber === streetNumber
          && completeAddresses[0].zipCode === zipCode
          && completeAddresses[0].city === city;
        if (completeAddresses.length === 1 && addressMatch) {
          yield put(fetchAddressSuggestionsSuccess(undefined));
          return true;
        }
      }

      yield put(fetchAddressSuggestionsSuccess(addresses));
      return false;

    }
  } catch (err) {
    if (!isBadRequestStatus(err)) {
      yield call(logErrorAndShowNotification, 'Could not fetch address suggestions', 'customer.addressSuggestions.error', err);
    }
    yield put(fetchAddressSuggestionsError(createAddressCheckErrorResponse(err?.response?.status)));
  }
}
