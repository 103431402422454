export default {
  'crossSellHints.ADT' : '„Ich empfehle Ihnen dazu das beliebte Zusatzprodukt.”',

  'crossSellHints.ADT_ACTIV' : '„Unser Angebot des Tages ist heute sehr beliebt, bestellen Sie es doch gleich mit.”',

  'crossSellHints.JDW' : '„Mein persönlicher Tipp zum Angebot der Woche.”',

  'crossSellHints.JDW_ACTIV' : '„Sichern Sie sich auch unser begehrtes Angebot der Woche.”',

  'crossSellHints.ERGANZEND' : '„Als ideale Ergänzung zu Ihrer Bestellung habe ich noch …”',

  'crossSellHints.PERFEKT' : '„Meine persönliche Empfehlung ist auch "{brand} + {upSellName}".”',

  'crossSellHints.PREISREDUZIERUNG' : '„Mit diesem Angebot sparen Sie "{reducedPriceEUR} {currencySymbol}" / "{reducedPricePERC} %".”',

  'crossSellHints.MARKE' : '„Darf ich Ihnen unser Treue-Abo mit vielen Vorteilen empfehlen.”',

  'crossSellHints.SAISON' : '“Exklusiv für Sie, empfehle ich Ihnen unser 30-Jahre-Angebot.”',

  'crossSellHints.STUNDE' : '„Unser limitiertes Angebot in dieser Stunde.”',

  'crossSellHints.SONDERANGEBOTE' : '„Viele Kunden sind von "{brand} + {upSellName}" begeistert.”',

  'crossSellHints.UPGRADE_SET' : '„Ich empfehle unser Sonderset. Sie erhalten Ihr Wunschprodukt zum Vorteilspreis und sparen dabei {reducedPriceEUR} {currencySymbol} / {reducedPricePERC} %”',

  'crossSellHints.ADS_AKTIV' : '„Meine persönliche Empfehlung ist das Angebot der Stunde.”',

  'crossSellHints.PDM_AKTIV' : '„Mein persönlicher Tipp ist …”',

  'crossSellHints.PREMIERE' : '„Mein persönlicher Tipp zum Angebot des Monats ...”',

  'crossSellHints.UPGRADE' : '„Ich empfehle unser Sonderset, bei dem Sie heute {DISCOUNT} sparen? Darin ist auch {NAME} enthalten.“',
};
