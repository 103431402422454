import { camelizeKeys } from 'humps';
import {
  call,
  getContext,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';

import { CountryCode } from 'src/api/orderTypes';
import { formatMessage } from 'src/i18n';
import { logErrorEvent } from 'src/logging/loggingActions';
import { openModal, showErrorNotification } from 'src/redux/app/appSlice';
import { appSelectors } from 'src/redux/app/selectors/appSelector';
import { addToBlockedDeliveryAddresses } from 'src/redux/customer/customerInfoSlice';
import { customerInfoSelectors } from 'src/redux/customer/selectors/customerInfoSelectors';
import {
  getBasketSuccess,
  getOffer,
  getOfferFailed,
  getOfferInProgress,
  getOfferSuccess,
  setOffer,
  updateLineItems
} from 'src/redux/order/orderEntrySlice';
import { orderEntrySelector } from 'src/redux/order/orderEntrySlice/selectors/orderEntrySelectors';
import { toggleChannelSwitchToOff } from 'src/redux/order/salesChannelSlice';
import { salesChannelSelector } from 'src/redux/order/selectors/salesChannelSelectors';
import { sourceChannelSelector } from 'src/redux/order/selectors/sourceChannelSelectors';
import { voucherSelector } from 'src/redux/order/selectors/voucherSelectors';
import { submitVoucherFailed, submitVoucherSuccess } from 'src/redux/order/voucherSlice';
import {
  addPaymentWarnings,
  DEFAULT_CH_PAYMENT_OPTIONS,
  getInstallmentFactoringPlansSuccess,
  getInstallmentPlansSuccess,
  resetInstallmentFactoringPayment,
  resetInstallmentPayment,
  resetSelectedPaymentMethod,
  setAndSubmitInstallmentFactoringPlan,
  setAvailablePaymentMethods,
  submitInstallmentPlan,
  toggleReklaZeroPercentFinancingFlag
} from 'src/redux/payment/paymentSlice';
import { paymentSelector } from 'src/redux/payment/selectors/paymentSelectors';
import { serviceVoucherSelectors } from 'src/redux/serviceVoucher/selectors/serviceVoucherSelectors';
import { changeServiceVoucher } from 'src/redux/serviceVoucher/serviceVoucherActions';
import { isInstallmentsPaymentError, isPaymentError } from 'src/sagas/offer/offerErrorsKeys';
import { SagaContextItem } from 'src/store/ReduxSagaContext';
import { DeliveryAddress } from 'src/types/customer/address';
import { Modals } from 'src/types/Modals';
import { StockLevel } from 'src/types/offer/Basket';
import { Offer, OfferItem } from 'src/types/offer/Offer';
import { OfferResponse } from 'src/types/offer/OfferResponse';
import { OrderErrorResponseTypes } from 'src/types/offer/OrderErrorResponseTypes';
import { PaymentMethod } from 'src/types/offer/PaymentMethod';
import { VoucherErrorResponse } from 'src/types/voucher/VoucherResponse';
import { getBasketId } from 'src/utils/basketId';
import { extractDetails, extractErrorKey } from 'src/utils/errorStatusChecks';
import { cleanFailedVoucherCode } from 'src/utils/getters/getFailedVoucherPosition';

import buildOfferRequest from '../utils/buildOfferRequest';


const { getPayment, isReklaZeroPercentFinancing } = paymentSelector;
const { getSalesChannel, getSalesChannelLoading } = salesChannelSelector;
const { getVoucher } = voucherSelector;
const { getOrderLineItems } = orderEntrySelector;
const { getSourceChannel } = sourceChannelSelector;

export interface OfferResponseResult {
  response?: OfferResponse;
  errorTitle?: OrderErrorResponseTypes;
}

export function* getOfferAndStoreBasket(action?: { type: string }) {
  try {
    return yield call(processAndGetOffer);
  } catch (err) {
    const salesChannelLoading = yield select(getSalesChannelLoading);

    const isTheGivenAddressWrong = err?.response?.data?.title?.includes('Ihre Rechnungsadresse wird nicht unterstützt');
    if (isTheGivenAddressWrong) {
      yield put(
        logErrorEvent({ message: `Get offer failed, action: ${JSON.stringify(action)}`, err: err }),
      );
    }

    const errorTitle = yield call(handleOfferResponseErrors, err);

    yield put(getOfferFailed());

    if (salesChannelLoading) {
      yield put(toggleChannelSwitchToOff());
    }
    return { errorTitle };
  }
}

export function* processAndGetOffer() {
  const salesChannel = yield select(getSalesChannel);
  const salesChannelLoading = yield select(getSalesChannelLoading);
  const sourceChannel = yield select(getSourceChannel);
  const isZeroPercent = yield select(isReklaZeroPercentFinancing);
  const isReklaOrder = yield select(orderEntrySelector.isReklaOrder);

  const basketId = getBasketId();
  if (!basketId) {
    if (salesChannelLoading) {
      yield put(toggleChannelSwitchToOff());
    }
    return;
  }

  const bankingDetails = yield select(orderEntrySelector.getCustomerBankingDetailsState);

  const countryCode = yield select(appSelectors.getCurrentCountry);
  const customerInfo = yield select(customerInfoSelectors.getCustomerInfo);
  const deliveryAddress = yield select(customerInfoSelectors.getSelectedDeliveryAddress);

  let voucher = undefined;
  const voucherErrorDetails = yield select(voucherSelector.getVoucherErrorDetail);
  if (!voucherErrorDetails) {
    voucher = yield select(getVoucher);
  }

  const payment = yield select(getPayment);

  const isServiceVoucherSelected = yield select(serviceVoucherSelectors.isServiceVoucherApplied);
  const apiOffer = yield getContext(SagaContextItem.apiOffer);
  yield put(getOfferInProgress());

  const buildOfferRequestResult = buildOfferRequest({
    customer: { info: customerInfo },
    payment: payment,
    voucher: voucher,
    freeShipping: isServiceVoucherSelected,
    bankingDetails,
    salesChannel,
    basketId,
    sourceChannel,
    countryCode,
    deliveryAddress,
    reklaFlag: isReklaOrder ? {
      isZeroPercent
    } : undefined
  });

  if(buildOfferRequestResult.invalidDataReason) {
    yield put(logErrorEvent({ message: `Build offer request error: customer data are not fully filled, ${buildOfferRequestResult.invalidDataReason}` }));
  }

  const offerResponse: OfferResponse = yield call(apiOffer.createOffer, buildOfferRequestResult.result);

  yield put(getOfferSuccess(offerResponse.offer));

  if (salesChannelLoading) {
    yield put(toggleChannelSwitchToOff());
  }

  if (offerResponse.paymentOptions) {
    yield put(setAvailablePaymentMethods(countryCode === CountryCode.ch ? DEFAULT_CH_PAYMENT_OPTIONS : offerResponse.paymentOptions));
  }

  if (offerResponse.installmentPlans && offerResponse.installmentPlans.length > 0 && payment.selectedPaymentMethod === PaymentMethod.installments) {
    yield put(getInstallmentPlansSuccess(offerResponse.installmentPlans));
  }

  if (offerResponse.installmentPlans && offerResponse.installmentPlans.length > 0 && payment.selectedPaymentMethod === PaymentMethod.installmentsFactoring) {
    yield put(getInstallmentFactoringPlansSuccess(offerResponse.installmentPlans));
  }

  if(offerResponse.offer.voucher) {
    yield put(submitVoucherSuccess(offerResponse.offer.voucher));
  }

  yield put(getBasketSuccess(offerResponse.offer.items));

  return {
    response: offerResponse,
  } as OfferResponseResult;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* handleOfferResponseErrors(error: any) {
  if (!error?.response) {
    yield put(showErrorNotification());
    return OrderErrorResponseTypes.unexpectedError;
  }

  const errorKey = extractErrorKey(error);
  let errorMessage = extractDetails(error);
  const { status, data } = error?.response ?? {};

  // Handle the delivery address in a category one error.
  if (data?.key?.includes('order_with_address_category1_not_allowed') ) {
    const selectedDeliveryAddress: DeliveryAddress | undefined = yield select(customerInfoSelectors.getSelectedDeliveryAddress);

    if (selectedDeliveryAddress?.id) {
      yield put(addToBlockedDeliveryAddresses(selectedDeliveryAddress.id));
    }
    yield put(openModal(Modals.blockedKAT1Address));
    return OrderErrorResponseTypes.addressCategory1Blocked;
  }

  if (isPaymentError(errorKey) || isInstallmentsPaymentError(errorKey)) {
    yield put(showErrorNotification(errorMessage));
    return OrderErrorResponseTypes.paymentError;
  }

  // handle timeout
  if (status === 504) {
    yield put(showErrorNotification('errors.timeout'));
    return OrderErrorResponseTypes.timeoutError;
  }

  // handle empty basket
  if (data?.type === OrderErrorResponseTypes.emptyBasket) {
    yield put(logErrorEvent({ message: ':mag_right: unexpected case: calculate offer returns empty basket error!' }));
    yield put(showErrorNotification('errors.basket.empty'));
    return OrderErrorResponseTypes.emptyBasket;
  }

  // handle voucher error
  if (data?.error_type === OrderErrorResponseTypes.voucherError) {
    const { detail, title, voucher_code: voucherCode, invalid_vouchers } = data;
    yield put(submitVoucherFailed({
      detail,
      title,
      voucherCode: cleanFailedVoucherCode(voucherCode),
      invalidVouchers: invalid_vouchers ?? [],
    } as VoucherErrorResponse));
    return OrderErrorResponseTypes.voucherError;
  }

  // handle invalid offer items
  if (data?.offer) {
    const offer = camelizeKeys(data.offer as Offer) as Offer;
    const items = offer.items;

    const soldOutItems = items.filter(item => isItemSellable(item));
    if (soldOutItems.length > 0) {
      const skus = soldOutItems.map(i => i.variant.sku).join(', ');
      errorMessage = `Artikel ${skus} ist mittlerweile ausverkauft, bitte aus dem Warenkorb nehmen`;
    }

    const unReservedItems = items.filter(item => item.reserved === false);
    if (unReservedItems.length > 0) {
      const { variant: { sku }, availableStockAmount } = unReservedItems[0];
      if (availableStockAmount && availableStockAmount > 0) {
        errorMessage = `Für ${sku} sind nur ${availableStockAmount} Produkte verfügbar. Bitte die Menge reduzieren.`;
      }
    }

    yield put(setOffer(offer));
    yield put(updateLineItems(items));
    yield put(showErrorNotification(errorMessage));
    return OrderErrorResponseTypes.invalidBasketItem;
  }

  yield put(showErrorNotification(errorMessage));
  return OrderErrorResponseTypes.unexpectedError;
}

function isItemSellable(item: OfferItem) {
  return item.stockLevel === StockLevel.soldOut || item.stockLevel === StockLevel.notSellable;
}

export function* getOfferIfOrderLineItemsExist() {
  const orderLineItems = yield select(getOrderLineItems);
  if (orderLineItems?.length) {
    yield call(getOfferAndStoreBasket);
  }
}

export function* checkAndResetPayment() {
  const selectedPayment = yield select(paymentSelector.getSelectedPaymentMethod);

  if (selectedPayment === PaymentMethod.installments) {
    yield put(resetSelectedPaymentMethod());
    yield put(resetInstallmentPayment());
    const warning = formatMessage('order.invalidInstallmentsPlans', { paymentLabel: 'Ratenzahlung' });
    yield put(addPaymentWarnings({ method: selectedPayment, warning }));
    return true;
  } else if (selectedPayment === PaymentMethod.installmentsFactoring) {
    yield put(resetSelectedPaymentMethod());
    yield put(resetInstallmentFactoringPayment());
    const warning = formatMessage('order.invalidInstallmentsPlans', { paymentLabel: 'Ratenkauf' });
    yield put(addPaymentWarnings({ method: selectedPayment, warning }));
    return true;
  }
  return false;
}

export function* changeServiceVoucherSaga() {
  yield call(checkAndResetPayment);
  yield call(getOfferAndStoreBasket);
}

export default function* getOfferWatcher() {
  yield takeLatest(getOffer.type, getOfferAndStoreBasket);
  yield takeLatest(changeServiceVoucher.type, changeServiceVoucherSaga);
  yield takeLatest(submitInstallmentPlan.type, getOfferAndStoreBasket);
  yield takeLatest(setAndSubmitInstallmentFactoringPlan.type, getOfferAndStoreBasket);
  yield takeLatest(toggleReklaZeroPercentFinancingFlag.type, getOfferAndStoreBasket);
}
