import {
  createAction,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import { DeliveryAddress } from 'src/types/customer/address';
import { OrderPreCheckResponse } from 'src/types/offer/OrderPreCheck';


export interface OrderPreCheckState {
  result?: OrderPreCheckResponse;
  loading: boolean;
}

export const orderPreCheckInitialState: OrderPreCheckState = {
  loading: false,
};

export interface OrderPreCheckPayload {
  deliveryAddress?: DeliveryAddress;
  orderValue: number;
  products?: (string | undefined)[];
  source?: string;
}

export const performOrderPreChecks = createAction<OrderPreCheckPayload>('orderPreCheck/performOrderPreChecks');


const orderPreCheckSlice = createSlice({
  name: 'orderPreCheck',
  initialState: orderPreCheckInitialState,
  reducers: {
    performOrderPreChecksSuccess(state, action: PayloadAction<OrderPreCheckResponse>) {
      state.result = action.payload;
      state.loading = false;
    },
    performOrderPreChecksFailed(state) {
      state.loading = false;
      state.result = orderPreCheckInitialState.result;
    },
  },
  extraReducers: builder => {
    builder.addCase( performOrderPreChecks,state => {
      state.loading = true;
    });
  },
});

export const {
  performOrderPreChecksSuccess,
  performOrderPreChecksFailed,
} = orderPreCheckSlice.actions;

export const orderPreCheckReducer = orderPreCheckSlice.reducer;
