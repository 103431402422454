export default {
  'customer.customerInfoTitle': 'Kundendaten',
  'customer.accountId': 'Kundennummer',
  'customer.orderRestriction': 'Auftragsbeschränkung',
  'customer.streetNumber': 'Hausnummer',
  'customer.zipCode': 'Postleitzahl',
  'customer.zipCodeShort': 'PLZ',
  'customer.city': 'Ort',
  'customer.addressAddition': 'Hausnummer-Zusatz',
  'customer.addressAdditionOptional': 'Hausnummer-Zusatz (optional)',
  'customer.locked': 'Kunde gesperrt',
  'customer.wrongCountry': 'Login nicht möglich - Falsches Auftragsland',
  'customer.wrongCountryMessage': 'Dieser Kunde wohnt nicht im Auftragsland und kann nicht eingeloggt werden',
  'customer.createEdit.isDefault': 'Als bevorzugte Lieferadresse markieren',
  'customer.info.careOf': 'C/O',
  // Validation
  'customer.createEdit.validation.required.salutation': 'Anrede ist ein pflichtfeld',
  'customer.createEdit.validation.required.firstName': 'Vorname ist ein pflichtfeld',
  'customer.createEdit.validation.required.lastName': 'Nachname ist ein pflichtfeld',
  'customer.createEdit.validation.required.phoneNumber': 'Bitte eine gültige telefonnummer eintragen',
  'customer.createEdit.validation.required.street': 'Straße ist ein pflichtfeld',
  'customer.createEdit.validation.required.streetNumber': 'Hausnummer ist ein pflichtfel',
  'customer.createEdit.validation.required.zipCode': 'PLZ ist ein pflichtfel',
  'customer.createEdit.validation.required.city': 'Stadt ist ein pflichtfel',
  // Advanced Search
  'customer.advancedSearch.customerNbr': 'KDNR',
  'customer.advancedSearch.postalCode': 'PLZ',
  'customer.advancedSearch.dateOfBirth': 'Geb.Datum',
  'customer.advancedSearch.link': 'Erweiterte Suche',
  'customer.advancedSearch.title': 'Kundensuche',
  'customer.advancedSearch.result': 'Suchergebnisse',
  'customer.advancedSearch.minFields': 'Es müssen mindestens 3 Felder...',
  'customer.advancedSearch.foundTooMuchCustomers': 'Mehr als 10 Treffer. Bitte weitere Suchkriterien hinzufügen.',
  'customer.firstName.minCaract': 'Der Vorname muss aus mindestens 1 Zeichen bestehen.',
  'customer.lastName.minCaract': 'Der Nachname muss aus mindestens 1 Zeichen bestehen.',
  'customer.careOf.minCaract': 'Der C/O muss aus mehr als 1 Zeichen bestehen.',
  'customer.firstName.validValue': 'Bitte Vorname prüfen',
  'customer.phoneNumber.validValue': 'Bitte Telefonnummer prüfen',
  'customer.email.validValue': 'Bitte E-Mail prüfen',
  'customer.lastName.validValue': 'Bitte Nachname prüfen',
  'customer.streetNumber.validValue': 'Bitte Hausnummer prüfen',
  'customer.streetNumber.startWithNumber': 'Hausnummer muss mit Zahl/ohne Leerzeichen beginnen.',
  'customer.street.validValue': 'Bitte Straße prüfen',
  'customer.zipCode.validValue': 'Bitte PLZ prüfen',
  'customer.city.validValue': 'Bitte Ort prüfen',
  'customer.notFound': 'Kunde nicht gefunden',
  'customer.somethingWrong': 'Mindestens drei Felder befüllen.',
  'customer.salutation.required': 'Bitte Anrede prüfen',
  'customer.dateOfBirth.required': 'Bitte Geburtsdatum prüfen',
  'customer.phoneNumber.required': 'Bitte Telefonnummer prüfen',
  'customer.email.required': 'Bitte E-Mail prüfen',
  'customer.street.required': 'Bitte Straße prüfen',
  'customer.street.noPackstation': 'Packstation darf nicht als Rechnungsadresse angegeben werden',
  'no invalid characters': 'Keine ungültigen Zeichen',
  'customer.moreThan10Customers': 'Es befinden sich mehr als 10 Kunden mit den eingegeben Kriterien. Bitte geben Sie weitere Kundenkriterien ein',
  'customer.messginInformation': 'Fehlende Information',
  'customer.phoneNumberMessing': 'Speditionsware: Bitte Telefonnummer für Liefervereinbarung hinterlegen.',
  'customer.EMPLOYEE': 'HSE Mitarbeiter',
  'customer.PARTNER': 'Partner / Journalist',
  'customer.CREATOR': 'HSE Creator',
  'customer.TV_CREATOR': 'TV Creator',
  'customer.INFLUENCER': 'Influencer',
  'customer.discount.voucher.EMPLOYEE': 'Gutscheineinlösung für HSE Mitarbeiter nicht möglich',
  'customer.discount.voucher.PARTNER': 'Gutscheineinlösung für Partner / Journalist nicht möglich',
  'customer.discount.voucher.CREATOR': 'Gutscheineinlösung für Creator nicht möglich',
  'customer.discount.installment.EMPLOYEE': 'Ratenzahlung für HSE Mitarbeiter nicht möglich. Bitte wählen Sie eine andere Zahlungsart aus.',
  'customer.discount.installment.PARTNER': 'Ratenzahlung für Partner / Journalist nicht möglich. Bitte wählen Sie eine andere Zahlungsart aus.',
  'customer.discount.installment.CREATOR': 'Ratenzahlung für Creator nicht möglich. Bitte wählen Sie eine andere Zahlungsart aus.',

  'customer.blocked': 'Kunde gesperrt ',
  'customer.blockedMessage': 'Bestellung nicht möglich (Auftrag Beschränkung) - Kunde Kann sich bei Ruckfragen schriftlich an HSE24 wenden',
  'customer.blocked.duplicate': 'Dublette prüfen',

  'customer.duplicate.hint': '„Ihre Daten wurden in einem bestehenden Kundenkonto gefunden. Bitte nennen Sie mir zum Datenabgleich Ihre Adresse.”',
  'customer.duplicate.useExistingAccount': 'Bestehendes Kundenkonto nutzen',
  'customer.duplicate.accountDoesNotBelongToCustomer': 'Das Konto gehört nicht zu dem Kunden',
  'customer.duplicate.email.wasFoundInExistingAccount': '„Ihre E-Mail-Adresse {email} wurden in einem bestehenden Kundenkonto gefunden. Bitte nennen Sie mir zum Datenabgleich Ihre Adresse.”',
  'customer.duplicate.email.createOrContinue': 'Neues Kundenkonto mit anderer E-Mail-Adresse anlegen oder ohne E-Mail-Adresse weiter',
  'customer.duplicate.email.back': 'Zurück zur Dateneingabe',

  'customer.blocked.ex_employee.create': 'Der Kunde ist nicht mehr Mitarbeiter. Bitte neue KDNR anlegen.',
  'customer.blocked.create': 'Neues Kundenkonto anlegen',
  'customer.blocked.logout': 'Kunde ausloggen',
  'customer.blocked.auth': 'Bitte Kunden authentifizieren.',
  'customer.blocked.hintMessage.asks_why': 'Wenn Kunde fragt wieso:',
  'customer.blocked.hintMessage.asks_why_answer': '„Die Kollegen der Serviceline helfen Ihnen gerne weiter.”',
  'customer.blocked.hintMessage.supportInfo': '„Die Bestellung ist leider nicht möglich. Bitte rufen Sie im Kundenservice an. Sie erreichen meine Kollegen von 8 bis 21 Uhr unter der Rufnummer: 0800 23 414 74.”',
  'customer.blocked.hintMessage.not_possible_to_order': '„Eine Bestellung ist leider nicht möglich. Sie können gerne schriftlich anfragen.”',
  'customer.blocked.hintMessage.postal_address_title': 'Postanschrift:',
  'customer.blocked.hintMessage.postal_address_value': 'HSE GmbH, Postfach 14 27, 85732 Ismaning',
  'customer.inputSearch': 'KDNR eingeben, Enter drücken',
  'customer.authentication': 'Authentifizierung',
  'customer.birthdayCheckText': '„Nennen Sie mir zum Datenabgleich bitte ihr Geburtsdatum.”',
  'customer.birthdayCheckText.incorrectPromptChoice': 'Bitte markieren Sie das richtige Geburtsdatum. Versuch in {countdown}s wiederholen',
  'customer.nameAddressCheckText': '„Bitte nennen Sie mir zum Datenabgleich Ihren Namen und Ihre Adresse.”',
  'customer.nameAddressCheckText.title': 'Geburtsdatum fehlt',
  'customer.nameAddressCheckText.birthdayText': '"In Ihrem Kundenkonto fehlt uns noch Ihr Geburtsdatum. Ich brauche diese Angabe bitte, um die Bestellung abzuschließen."{br}(Wir benötigen es, um sicherzustellen, dass niemand unberechtigt auf Ihr Konto zugreifen kann und um Ihre Volljährigkeit zu bestätigen.)',
  'customer.nameAddressCheckText.submit': 'Speichern',
  'customer.nameAddressCheckText.abort': 'Abbrechen und Kunden ausloggen',
  // pin texts
  'customer.pin.enter_pin': '„Bitte nennen Sie mir die {position} 3 Ziffern Ihrer Telefon-PIN zum Datenabgleich.“',
  'customer.pin.alternative_reasons': 'Alternativ 2: Grund fur Bearbeitung ohne Telefon-PIN erfassen',
  'customer.pin.login_reason.choose_reason': 'Wählen Sie einen Grund',
  'customer.pin.login_reason.NOT_ON_PHONE': 'KD nicht am Telefon - Backofficetätigkeit',
  'customer.pin.login_reason.IT_PROCESSING': 'IT-Bearbeitung',
  'customer.pin.login_reason.RECREATE_REJECTED_ORDER': 'Abgelehnte Bestellung neu erstellen',
  'customer.pin.wrong_pin': 'Telefon-PIN falsch ({attempts} von 5 Versuchen). Bitte erneut versuchen oder den Kunden fragen, ob neue PIN versendet werden soll.',
  'customer.pin.request_new_pin': 'Neue Telefon-PIN versenden?',
  'customer.pin.request_new_pin.address_message': 'Soll eine neue Telefon-PIN an die Meldeadresse versendet werden?',
  'customer.pin.yes': 'JA',
  'customer.pin.no': 'NEIN',
  'customer.pin.request_new_pin.success_message': 'Brief zur neuen Telefon-PIN wurde versendet.',
  'customer.pin.request_new_pin.fail_message': 'Korrektur der Meldeadresse über die Service-Hotline nötig.',
  'customer.pin.max_attempt_error_message': 'Die max. Anzahl an Fehlversuchen ist erreicht. Bitte KD fragen: „Wünschen Sie eine neue Telefon-PIN? Bis zum Erhalt können Sie gerne online bestellen.“',
  'customer.pin.already_reset': 'Neue Telefon-PIN wurde am {date} versendet. Sollte der Brief innerhalb von 5 Werktagen nicht ankommen, kann PIN erneut angefordert werden.',

  'customer.logIn': 'Einloggen',
  'customer.loginAccount': 'Kundenkonto einloggen',
  'customer.blocked.loginAccountsSubtitle': 'Bitte folgenden Kundenkonten überprüfen und das richtige einloggen.',
  'customer.newCustomer.welcome': 'Wenn Ihnen etwas nicht gefällt, haben Sie die Möglichkeit, es innerhalb {br}von 30 Tagen kostenfrei zurück zu senden. Sofern die Ware vollständig {br}ist und sich in einem neuwertigen Zustand befindet wie bei Erhalt. Den Retouren-Aufkleber finden Sie auf dem Lieferschein.{br}{br}' +
    'Übrigens zahlen Sie nur einmal am Tag 5,95 Euro Versandkosten. Wenn Sie {br}also heute noch etwas Tolles entdecken - rufen Sie uns gerne an - wir packen es zu Ihrer aktuellen Bestellung dazu.{br}',
  'customer.ZipCodeError': 'Meinst du {zip}, {city}? Bitte die Kombination von PLZ und Ort prüfen. ',

  'customer.givenAddress':'Adressdaten überprüft, mit angegebener Adresse fortfahren',
  'customer.lastTenCustomers': 'Letzte 10 Kunden',
  'customer.new': 'Neukunde',
  'customer.dateOfBirth': 'Geburtsdatum',
  'customer.emailAddress': 'E-Mail-Adersse',
  'customer.phoneNumber': 'Telefonnummer',

  'customer.blocked.reason.DUPLICATE': 'Dublette/Datenzusammenführung',
  'customer.blocked.reason.DELETION_GDPR': 'Dublette/Löschung DSGVO',
  'customer.blocked.reason.PERMANANT_RETURNER': 'Dauerretournierer',
  'customer.blocked.reason.CUSTOMER_REQUIREMENT': 'Kundenwunsch',
  'customer.blocked.reason.UNKNOWN_ADDRESS': 'Adresse unbekannt',
  'customer.blocked.reason.DRM_LOCK': 'DRM Sperre',
  'customer.blocked.reason.FRAUD': 'Betrüger',
  'customer.blocked.reason.DECEASED': 'Verstorben',
  'customer.blocked.reason.NON_PAYERU': 'Nichtzahler',
  'customer.blocked.reason.PRANK_ORDER': 'Juxbesteller',
  'customer.blocked.reason.UNDERAGED': 'Minderjährig',
  'customer.blocked.reason.DISABILITY': 'Unmündig',
  'customer.blocked.reason.AS_INTERNAL': 'AS intern',
  'customer.blocked.reason.DATA_PRIVACY_LOCK': 'Datenschutzsperrung',
  'customer.blocked.reason.ADDRESS_CHECK_MISSING': 'Adressprüfung fehlt',
  'customer.blocked.reason.DEPARTED_EMPLOYEE': 'MA ausgeschieden',
  'customer.blocked.reason.NON_PAYER_KSV': 'Nichtzahler KSV',
  'customer.blocked.reason.NON_PAYER_REAL': 'Nichtzahler Real',
  'customer.blocked.reason.NON_PAYER_KSP': 'Nichtzahler KSP',
  'customer.blocked.reason.NON_PAYER_INFOSCORE': 'Sperre Nichtzahler',
  'customer.blocked.reason.DEPARTED_CREATOR': 'Creator ausgeschieden',
  'customer.blocked.reason.ARCHIVED': 'Archiviert (EOB)',

  'customer.blocked.Deletion_GDPR.request_unlock': '„Ihr Konto wurde auf Ihren eigenen Wunsch hin gesperrt. Bitte senden Sie Ihren Entsperrungswunsch mit Angabe Ihrer Kundennummer an folgende Adresse:',
  'customer.blocked.Deletion_GDPR.de_address': 'Home Shopping Europe GmbH{br}Münchener Str. 101h{br}85737 Ismaning',
  'customer.blocked.Deletion_GDPR.at_address': 'HSE Austria{br}Service-Center c/o TEL24{br}Franz-Josef-Str. 15{br}5020 Salzburg',
  'customer.blocked.Deletion_GDPR.unblocking_hint': 'Die Entsperrung ist ausschließlich auf dem Postweg möglich.“',

  'waitlistHistory.openButton': 'Warteliste',
  'subscriptionHistory.openButton': 'Treue-Abo',

  'customer.pinVerification.error_request': 'Tut uns leid, wir können die PIN momentan nicht verifizieren.',
  'customer.skipPinVerification.error_request': 'Leider können wir die PIN-Bestätigung derzeit nicht überspringen.',
  'customer.requestNewVerificationPin.error_request': 'Leider können wir derzeit keine Neukunden-PIN-Bestätigung anfordern.',
  'customer.requestNewVerificationPin.alreadySent': 'Anfrage für eine neue Kunden-PIN bereits versendet',
  'customer.bypassBirthdayCheck': 'ohne Abfrage weitermachen'

};
