import { VoucherFailureResponse } from 'src/types/offer/ErrorResponse';
import { VoucherRestriction } from 'src/types/voucher/VirtualVoucherRequest';
import { VirtualVoucherType } from 'src/types/voucher/VirtualVoucherType';


export type VoucherResponse = ValueVoucher | PercentageVoucher | FreeShippingVoucher;

export type ValueVoucher = {
  type: VirtualVoucherType.value;
  value: number;
  restrictions?: VoucherRestriction[];
  code: string;
  progressiveDiscountInfo?: ProgressiveDiscountInfo;
};

export type PercentageVoucher = {
  type: VirtualVoucherType.percentage;
  value: number;
  calculatedPercentageValue?: number;
  percentage: number;
  restrictions?: VoucherRestriction[];
  code: string;
  progressiveDiscountInfo?: ProgressiveDiscountInfo;
};

export type FreeShippingVoucher = {
  type: VirtualVoucherType.freeShipping;
  code: string;
  value: number;
  restrictions?: VoucherRestriction[];
}

export type VoucherErrorResponse = {
  detail: string;
  title: InvalidVoucherReason | string;
  voucherCode: string;
  restrictions?: VoucherRestriction[]
  invalidVouchers?: VoucherFailureResponse[];
}

export interface ProgressiveDiscountInfo {
  discounts: ProgressiveDiscount[];
  selectedDiscount: number;
  remainingAmountValue: number;
}

export interface ProgressiveDiscount {
  minOrderValue: number;
  discountValue: number;
}

export enum InvalidVoucherReason {
  VOUCHER_USED_SAME_DAY = 'VOUCHER_USED_SAME_DAY',
  VOUCHER_USED = 'VOUCHER_USED'
}
