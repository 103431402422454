import { PayloadAction } from '@reduxjs/toolkit';
import {
  call,
  getContext,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';

import { ApiOffer } from 'src/api/apiOffer';
import { logErrorEvent } from 'src/logging/loggingActions';
import {
  OrderPreCheckPayload,
  performOrderPreChecks,
  performOrderPreChecksFailed,
  performOrderPreChecksSuccess,
} from 'src/orderPreCheck/redux/orderPreCheckSlice';
import { customerInfoSelectors } from 'src/redux/customer/selectors/customerInfoSelectors';
import { buildInvoiceAddressFromCustomer } from 'src/sagas/utils/buildOfferRequest';
import { SagaContextItem } from 'src/store/ReduxSagaContext';
import { Customer } from 'src/types/customer/customer';
import { OrderPreCheckRequest } from 'src/types/offer/OrderPreCheck';
import formatBirthdayToServicesFormat from 'src/utils/formatters/formatBirthdayToServicesFormat';
import { trimStringOrNull } from 'src/utils/trimStringOrNull';


export function* performOrderPreChecksSaga(action: PayloadAction<OrderPreCheckPayload>) {
  try {
    const customer = yield select(customerInfoSelectors.getCustomer);
    // TODO: refactor AddressDisplay component to avoid having this bad dependency
    const isInvoiceAddressSelected = yield select(customerInfoSelectors.billingAsDeliveryAddress);
    const apiOffer: ApiOffer = yield getContext(SagaContextItem.apiOffer);

    const request = buildOrderPreCheckRequest(action.payload, customer, isInvoiceAddressSelected);
    const result = yield call(apiOffer.preCheckOrder, request);
    yield put(performOrderPreChecksSuccess(result));
  } catch (err) {
    yield put(performOrderPreChecksFailed());
    yield put(logErrorEvent({ message: 'Could not preform order pre checks', err, args: action.payload }));
  }
}

function buildOrderPreCheckRequest(
  orderPreCheck: OrderPreCheckPayload,
  customer: Customer,
  isInvoiceAddressSelected?: boolean,
): OrderPreCheckRequest {
  const billingAddress = customer.billingAddress;
  const deliveryAddress = orderPreCheck.deliveryAddress ?? (
    isInvoiceAddressSelected ? buildInvoiceAddressFromCustomer(customer) : undefined
  );
  if(!billingAddress || !customer.salutation || !deliveryAddress) {
    throw new Error(
      `Kundeninformationen oder Lieferadresse fehlen:
      isInvoiceAddressSelected: ${!!isInvoiceAddressSelected},
      billingAddressPresent: ${!!billingAddress},
      customerSalutation: ${customer.salutation},
      deliveryAddressPresent: ${!!deliveryAddress},
      orderPreCheckDeliveryAddress: ${!!orderPreCheck.deliveryAddress},
      customerId: ${customer.id}`
    );
  }

  return {
    orderValue: orderPreCheck.orderValue ?? 0,
    deliveryAddress,
    customer: {
      salutation: customer.salutation,
      firstName: customer.firstName,
      lastName: customer.lastName,
      street: billingAddress.street,
      streetNumber: billingAddress.streetNumber,
      addressAddition: billingAddress.addressAddition,
      zipCode: billingAddress.zipCode,
      city: billingAddress.city,
      countryCode: billingAddress.countryCode,
      careOf: billingAddress.careOf,
      email: customer.email,
      phone: customer.phoneNumber,
      dateOfBirth: formatBirthdayToServicesFormat(customer.dateOfBirth),
      dplCustomerId: trimStringOrNull(customer.id),
      crmPartnerId: trimStringOrNull(customer.externalCustomerId),
    },
  };
}

export function* performOrderPreChecksWatcher() {
  yield takeLatest(performOrderPreChecks.type, performOrderPreChecksSaga);
}
