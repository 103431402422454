import { PayloadAction } from '@reduxjs/toolkit';
import {
  call,
  put,
  takeLatest
} from 'redux-saga/effects';

import { logErrorEvent } from 'src/logging/loggingActions';
import { openModal } from 'src/redux/app/appSlice';
import {
  addCustomerConsent,
  addCustomerReceptionProviderSuccess,
  addDeliveryAddressSuccess,
  checkCustomerByNameAndBirthday,
  createCustomerSuccess,
  fetchCustomer,
  loadCustomerFromLocalHistory,
  setCustomerEmail,
  setCustomerPhoneNumber,
  setSelectedCustomerLocalId
} from 'src/redux/customer/customerInfoSlice';
import { getOffer } from 'src/redux/order/orderEntrySlice';
import { savePaymentData } from 'src/redux/payment/paymentSlice';
import { MarketResearchConsent } from 'src/types/customer/customer';
import { Modals } from 'src/types/Modals';
import localCustomerStorage from 'src/utils/localCustomerStorage';


export function* getSavedCustomer({ payload }: PayloadAction<string>) {
  try {
    const savedCustomer = localCustomerStorage.findLastNewCustomer(payload);

    if (savedCustomer) {
      const { id, customer, deliveryAddress, payment, consents } = savedCustomer;
      yield call(localCustomerStorage.updateCustomerLoginTime, id);

      if (customer.externalCustomerId) {
        yield put(fetchCustomer(customer.externalCustomerId));
      } else {
        yield put(setSelectedCustomerLocalId(id));
        yield put(checkCustomerByNameAndBirthday(customer));
        yield put(createCustomerSuccess(customer));
        if (deliveryAddress) {
          yield put(addDeliveryAddressSuccess(deliveryAddress));
        }
        if (payment) {
          yield put(savePaymentData(payment));
        }
        yield put(addCustomerReceptionProviderSuccess(savedCustomer.reception));

        if (consents) {
          const { phoneMarketResearch, emailMarketResearch } = consents;
          if (phoneMarketResearch !== MarketResearchConsent.NOT_QUERIED && emailMarketResearch !== MarketResearchConsent.NOT_QUERIED) {
            yield put(addCustomerConsent({
              phoneMarketResearch: phoneMarketResearch === MarketResearchConsent.ALLOWED,
              emailMarketResearch: emailMarketResearch === MarketResearchConsent.ALLOWED,
            }));
          } else {
            yield put(openModal(Modals.consentManagement));
          }

          if (consents.phoneNumber && !customer.phoneNumber) {
            yield put(setCustomerPhoneNumber(consents.phoneNumber));
          }

          if (consents.email && !customer.email) {
            yield put(setCustomerEmail(consents.email));
          }
        } else {
          yield put(openModal(Modals.consentManagement));
        }

        yield put(getOffer());
      }
    } else {
      yield put(setSelectedCustomerLocalId(undefined));
    }
  } catch (err) {
    yield put(logErrorEvent({ message: `Could not load saved customer ${payload}`, err }));
    yield put(setSelectedCustomerLocalId(undefined));
  }
}

export default function* getSavedCustomerWatcher() {
  yield takeLatest(loadCustomerFromLocalHistory.type, getSavedCustomer);
}
