import salesChannels from 'src/config/salesChannels';
import { Dimension } from 'src/types/offer/Basket';
import { OfferItem } from 'src/types/offer/Offer';
import { CrossSellType } from 'src/types/product/CrossSell';

import toBaseProductNumber from './formatters/toBaseProductNumber';


const NOT_AVAILABLE = 'not available';
const MAIN_PRODUCT = 'Main Product';
const CROSS_SELL = 'Cross sell product';
const TIME_UPSELL = 'Time upsell';
const NOT_TRACKED = 'not tracked';

export function parseOrderItems(items: OfferItem[] | undefined, voucherCode: string | undefined, salesChannelId: string) {
  const salesChannelName =
    salesChannels.find(channel => channel.id === salesChannelId)?.name || 'Other';

  return items?.map(item => {
    const listValue = item.crossSell?.leadProductSku ?
      `${toBaseProductNumber(item.crossSell.leadProductSku)} | ${item.crossSell.leadProductName ?? NOT_AVAILABLE}` :
      item.baseProductNo ? `${toBaseProductNumber(item.baseProductNo)} | ${item.name.long}` : NOT_AVAILABLE;
    return {
      name: item.baseProductNo ? `${toBaseProductNumber(item.baseProductNo)} | ${item.name.long}` : NOT_AVAILABLE,
      id: item.baseProductNo,
      price: item.variant.price.value,
      list: listValue,
      brand: item.brand?.brandName || NOT_AVAILABLE,
      category: item.categoryPath || NOT_AVAILABLE,
      variant: parseVariant(item) || NOT_AVAILABLE,
      quantity: item.quantity,
      coupon: item?.voucherDiscountValue ? voucherCode : NOT_AVAILABLE,
      dimension10: item.wgh || item.baseProductNo || NOT_AVAILABLE,
      dimension2: item.crossSell?.id ? CROSS_SELL : MAIN_PRODUCT,
      dimension8: item?.salesdrivers ? item?.salesdrivers[0].typeCode : NOT_AVAILABLE,
      dimension9: item.variant.price.label || NOT_AVAILABLE,
      dimension12: salesChannelName,
      dimension13: item.crossSell && item.crossSell?.type === CrossSellType.STUNDE ? TIME_UPSELL : NOT_TRACKED,
    };
  });
}

function parseVariant(item: OfferItem): string {
  return item.variant?.dimensions?.map(parseDimension).join('|') ?? '';
}

function parseDimension(dimension: Dimension) {
  return `${dimension.type}:${dimension.value}`;
}
