import {
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';

import { logErrorEvent } from 'src/logging/loggingActions';
import { closeModal, showSuccessNotification } from 'src/redux/app/appSlice';
import { emptyAddressSuggestions } from 'src/redux/customer/addressSuggestionsSlice';
import {
  CustomerInfoActionTypes,
  editCustomerError,
  EditCustomerResponse,
  editCustomerSuccess,
} from 'src/redux/customer/customerInfoSlice';
import { customerInfoSelectors } from 'src/redux/customer/selectors/customerInfoSelectors';
import { DeliveryAddress, DeliveryAddressType } from 'src/types/customer/address';
import { Modals } from 'src/types/Modals';
import localCustomerStorage from 'src/utils/localCustomerStorage';

import { ValidateAddress } from '../addressSuggestions/addressSuggestions';
import { getOfferIfOrderLineItemsExist } from '../offer/getOffer';
import { mapBillingToDeliveryAddress } from '../utils/buildOrderRequest';


export function* editCustomer(action: EditCustomerResponse) {
  try {
    const customer = yield select(customerInfoSelectors.getCustomerInfo);
    const isBillingAddressValidated = yield select(customerInfoSelectors.billingAddressValidated);
    // We need to update the id because we get it empty from the component.
    // We will use the current customer id from the store.
    const payload = action.payload;
    payload.id = customer.customerResponse.id;
    const { billingAddress } = payload;
    const validAddress = yield call(ValidateAddress, isBillingAddressValidated, billingAddress);
    if (validAddress) {
      const updatedDeliveryAddresses = customer.customerResponse.deliveryAddresses?.map((address: DeliveryAddress) => {
        if (address.type === DeliveryAddressType.Postal && address.fromInvoiceAddress) {
          return {
            ...mapBillingToDeliveryAddress(billingAddress!, customer.customerResponse),
            id: address.id,
            externalAddressId: address.externalAddressId,
            fromInvoiceAddress: true,
          };
        }
        return address;
      });
      const customerData = {
        ...payload,
        deliveryAddresses: updatedDeliveryAddresses,
      };
      yield put(editCustomerSuccess(customerData));
      yield put(closeModal(Modals.customer));
      yield put(emptyAddressSuggestions());
      yield call(localCustomerStorage.upsertCustomer, payload, customer?.selectedLocalCustomerId);
      yield put(showSuccessNotification('customer.createEdit.editSuccess'));
      yield call(getOfferIfOrderLineItemsExist);
    }
  } catch (err) {
    yield put(logErrorEvent({ message: `Could not edit customer ${action.payload?.id}`, err }));
    const errorCode = (err?.response?.status) || 400;
    yield put(editCustomerError(errorCode));
  }
}

export default function* editCustomerWatcher() {
  yield takeLatest(CustomerInfoActionTypes.EDIT_CUSTOMER, editCustomer);
}
