import { addCustomerConsentWatcher } from 'src/sagas/customer/addCustomerConsent';
import { addCustomerReceptionProviderWatcher } from 'src/sagas/customer/addCustomerReceptionProvider';
import { checkCustomerByNameAndBirthdayWatcher } from 'src/sagas/customer/CheckCustomerByNameAndBirthday';
import { checkCustomerEmailWatcher } from 'src/sagas/customer/CheckCustomerEmail';
import createCustomerWithDuplicatedCheckWatcher from 'src/sagas/customer/createCustomerWithDuplicatedCheck';
import fetchCustomerByNumberWatcher from 'src/sagas/customer/fetchCustomerByNumberSaga';
import fetchDuplicatedCustomersWatcher from 'src/sagas/customer/fetchDuplicatedCustomers';
import getSavedCustomerWatcher from 'src/sagas/customer/getSavedCustomer';
import logoutCustomerWatcher from 'src/sagas/customer/logoutCustomerSaga';
import setPreferredPaymentMethodWatcher from 'src/sagas/orderEntry/setPreferredPaymentSaga';

import { addCustomerPrivacyWatcher } from './addCustomerPrivacy';
import addDeliveryAddressWatcher from './addDeliveryAddress';
import createCustomerWatcher from './createCustomer';
import editCustomerWatcher from './editCustomer';
import editDeliveryAddressWatcher from './editDeliveryAddress';
import fetchCustomerWatcher from './fetchCustomer';
import resetCustomerWatcher from './resetCustomer';
import setSelectedDeliveryAddressWatcher from './setSelectedDeliveryAddress';
import updateCustomerPreferencesAddressWatcher from './updateCustomerPreferencesAddressSaga';
import verifyDhlPostNumberWatcher from '../dhlPostNumber/verifyDhlPostNumber';


export default [
  fetchCustomerWatcher,
  createCustomerWatcher,
  editCustomerWatcher,
  addDeliveryAddressWatcher,
  editDeliveryAddressWatcher,
  resetCustomerWatcher,
  setSelectedDeliveryAddressWatcher,
  setPreferredPaymentMethodWatcher,
  addCustomerConsentWatcher,
  checkCustomerEmailWatcher,
  checkCustomerByNameAndBirthdayWatcher,
  addCustomerPrivacyWatcher,
  createCustomerWithDuplicatedCheckWatcher,
  getSavedCustomerWatcher,
  fetchDuplicatedCustomersWatcher,
  addCustomerReceptionProviderWatcher,
  logoutCustomerWatcher,
  fetchCustomerByNumberWatcher,
  updateCustomerPreferencesAddressWatcher,
  verifyDhlPostNumberWatcher,
];
