import { PayloadAction } from '@reduxjs/toolkit';
import {
  call,
  getContext,
  put,
  takeLatest,
} from 'redux-saga/effects';

import { ApiCustomer } from 'src/api/apiCustomer';
import { logErrorEvent } from 'src/logging/loggingActions';
import { openModal } from 'src/redux/app/appSlice';
import {
  fetchCustomerByEmailSuccess,
  setCustomerCreateFormValues,
  fetchCustomerByEmailEnds,
  searchForCustomerByEmail,
} from 'src/redux/customer/customerSuggestionsSlice';
import { SagaContextItem } from 'src/store/ReduxSagaContext';
import { Customer } from 'src/types/customer/customer';
import { Modals } from 'src/types/Modals';
import { isNotFoundStatus } from 'src/utils/errorStatusChecks';
import { validateEmail } from 'src/utils/validators/validation';


export function* searchForCustomerByEmailSaga(action: PayloadAction<Partial<Customer>>) {
  try {
    const email = action.payload?.email?.trim() ?? '';
    if (!validateEmail(email)) {
      return;
    }

    const customerApi: ApiCustomer = yield getContext(SagaContextItem.apiCustomer);
    const response: Customer = yield call(customerApi.fetchCustomerByEmail, email);
    if (response) {
      yield put(fetchCustomerByEmailSuccess(response));
      yield put(setCustomerCreateFormValues(action.payload));
      if (response.isHelloUser) {
        return;
      }
      yield put(openModal(Modals.duplicatedEmail));
    }
  } catch (err) {
    if(!isNotFoundStatus(err)) {
      yield put(logErrorEvent({ message: 'Could not check customer by email', err }));
    }
  } finally {
    yield put(fetchCustomerByEmailEnds());
  }
}


export function* checkCustomerEmailWatcher() {
  yield takeLatest(searchForCustomerByEmail.type, searchForCustomerByEmailSaga);
}
