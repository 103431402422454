import {
  createAction,
  createSlice,
  PayloadAction
} from '@reduxjs/toolkit';

import { Customer } from 'src/types/customer/customer';


export interface CustomerSuggestionsState {
  loading: boolean;
  customerFormValues?: Partial<Customer>;
  customer?: Customer;
  customers?: Customer[];
  ignoreCustomerWithExternalId?: string;
  error?: boolean;
  errorCode?: number;
}

export const customerSuggestionsInitialState: CustomerSuggestionsState = {
  loading: false,
};

export const fetchDuplicatedCustomers = createAction<Customer>('customerSuggestions/fetchDuplicatedCustomer');
export type FetchDuplicatedCustomers = ReturnType<typeof fetchDuplicatedCustomers>;
export const searchForCustomerByEmail = createAction<Partial<Customer>>('customer/checkCustomerEmail');

const customerSuggestionsSlice = createSlice({
  name: 'customerSuggestions',
  initialState: customerSuggestionsInitialState,
  reducers: {
    fetchCustomerByEmailSuccess(state, { payload }: PayloadAction<Customer>) {
      state.loading = false;
      state.customer = payload;
    },
    fetchCustomerByEmailEnds(state) {
      state.loading = false;
    },
    fetchCustomerByNameAndBirthdaySuccess(state, { payload }: PayloadAction<Customer[]>) {
      state.loading = false;
      state.customers = payload;
    },
    cleanCustomerSuggestion(state) {
      state.loading = false;
      state.customer = undefined;
      state.customers = undefined;
      state.customerFormValues =undefined;
    },
    setCustomerCreateFormValues(state, { payload }: PayloadAction<Partial<Customer>>){
      state.customerFormValues = payload;
    },
    setIgnoreCustomerWithExternalId(state, { payload }: PayloadAction<string|undefined>){
      state.ignoreCustomerWithExternalId = payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(searchForCustomerByEmail, state => { state.loading = true; });
  },
},
);

export const {
  fetchCustomerByEmailSuccess,
  fetchCustomerByNameAndBirthdaySuccess,
  cleanCustomerSuggestion,
  setCustomerCreateFormValues,
  fetchCustomerByEmailEnds,
  setIgnoreCustomerWithExternalId
} = customerSuggestionsSlice.actions;

export default customerSuggestionsSlice.reducer;
