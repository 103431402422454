import { camelizeKeys } from 'humps';
import {
  call,
  getContext,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import { ApiGuestOrder } from 'src/api/apiGuestOrder';
import { CountryCode } from 'src/api/orderTypes';
import { logErrorEvent, logEvent } from 'src/logging/loggingActions';
import { LOG_LEVEL } from 'src/logging/loggingService';
import { openModal, showErrorNotification } from 'src/redux/app/appSlice';
import { appSelectors } from 'src/redux/app/selectors/appSelector';
import { CustomerInfoState } from 'src/redux/customer/customerInfoSlice';
import { customerInfoSelectors, isPhoneNumberRequired } from 'src/redux/customer/selectors/customerInfoSelectors';
import {
  newOrderError,
  newOrderSuccess,
  requestNewOrder,
} from 'src/redux/order/newOrderSlice';
import { deleteBasketSuccess } from 'src/redux/order/orderEntrySlice';
import { orderEntrySelector } from 'src/redux/order/orderEntrySlice/selectors/orderEntrySelectors';
import { newOrderSelector } from 'src/redux/order/selectors/newOrderSelectors';
import { salesChannelSelector } from 'src/redux/order/selectors/salesChannelSelectors';
import { voucherSelector } from 'src/redux/order/selectors/voucherSelectors';
import {
  resetChEntryPayments,
  resetInstallmentFactoringPayment,
  resetInstallmentPayment,
  resetPayment,
  resetSelectedPaymentMethod,
  setAvailablePaymentMethods
} from 'src/redux/payment/paymentSlice';
import { paymentSelector } from 'src/redux/payment/selectors/paymentSelectors';
import { recreateOrderSelectors } from 'src/redux/recreateOrder/selectors/recreateOrderSelectors';
import { serviceVoucherSelectors } from 'src/redux/serviceVoucher/selectors/serviceVoucherSelectors';
import { checkAndUpdateMarketResearchConsentSaga } from 'src/sagas/orderEntry/checkAndUpdateMarketResearchConsentSaga';
import handleGuestCustomerConsents from 'src/sagas/orderEntry/handleGuestCustomerConsents';
import { SagaContextItem } from 'src/store/ReduxSagaContext';
import { DeliveryAddressType } from 'src/types/customer/address';
import { Modals } from 'src/types/Modals';
import { CreateOrderResultResponse } from 'src/types/offer/CreateOrderResultResponse';
import { PaymentMethodNotAllowedErrorData } from 'src/types/offer/ErrorResponse';
import { Payment } from 'src/types/offer/Payment';
import { PaymentMethod } from 'src/types/offer/PaymentMethod';
import { PaymentOption } from 'src/types/offer/PaymentOption';
import {
  getBasketId,
  isBasketUsedPreviously,
  removeBasketId,
  trackCreatedOrderDeletedBasket
} from 'src/utils/basketId';
import { extractDetails } from 'src/utils/errorStatusChecks';
import localCustomerStorage from 'src/utils/localCustomerStorage';
import { getReklaOrderInfoFromSession, removeReklaOrderInfoFromSession } from 'src/utils/reklaOrderUtils';

import { setPreferredPaymentSaga } from './setPreferredPaymentSaga';
import trackOrder from './trackOrder';
import buildOrderRequest from '../utils/buildOrderRequest';
import { buildPayment } from '../utils/buildPayment';


const { getSalesChannel } = salesChannelSelector;


function areMethodsDisabled(availablePaymentMethods: PaymentOption[]) {
  return availablePaymentMethods.find(item => item.enabled) === undefined;
}

export function* newOrder() {
  const basketId = getBasketId();
  const payment = yield select(paymentSelector.getPayment);
  const { getCustomerBankingDetailsState, getVoucher, getOrderLineItems, getOrderSalesOffice, getOrderSourceChannel, isReklaOrder } = orderEntrySelector;
  try {
    const lastLoading = yield select(newOrderSelector.getLastLoadingStatus);
    if (lastLoading) {
      yield put(logErrorEvent({ message: 'sent same create order request multiple times :warning:' }));
    }
    const { isExistingCustomer } = customerInfoSelectors;
    const apiGuestOrder: ApiGuestOrder = yield getContext(SagaContextItem.apiGuestOrder);
    const customerInfo: CustomerInfoState = yield select(customerInfoSelectors.getCustomerInfo);
    const bankingDetails = yield select(getCustomerBankingDetailsState);
    const builtPayment = buildPayment(payment, bankingDetails) as Payment;
    const voucherErrorDetails = yield select(voucherSelector.getVoucherErrorDetail);
    const voucher = yield select(getVoucher);
    const orderItems = yield select(getOrderLineItems);
    const salesOffice = yield select(getOrderSalesOffice);
    const salesChannel = yield select(getSalesChannel);
    const phoneNumberRequired = yield select(isPhoneNumberRequired);
    const country = yield select(appSelectors.getCountry);
    const serviceVoucher = yield select(serviceVoucherSelectors.getServiceVoucher);
    const existingCustomer = yield select(isExistingCustomer);
    const isServiceVoucherSelected = yield select(serviceVoucherSelectors.isServiceVoucherApplied);
    const customer = yield select(customerInfoSelectors.getCustomer);
    const sourceChannel = yield select(getOrderSourceChannel);
    const localCustomerId = yield select(customerInfoSelectors.getSelectedCustomerLocalId);
    const availablePaymentMethods = payment.availablePaymentMethods;
    const preferredPaymentMethod = payment.preferredPaymentMethod;
    const recreatedOriginalOrderId = yield select(recreateOrderSelectors.getRecreatedOriginalOrder);
    const deliveryAddress = yield select(customerInfoSelectors.getSelectedDeliveryAddress);
    const isRekla = yield select(isReklaOrder);
    const previousOrder = getReklaOrderInfoFromSession();
    const isZeroPercent = yield select(paymentSelector.isReklaZeroPercentFinancing);

    const reklaFlag = isRekla ? {
      originalExternalOrderId: previousOrder ? previousOrder.externalOrderId : undefined,
      isZeroPercent: isZeroPercent
    } : undefined;

    const [order, translationKey] = buildOrderRequest({
      voucher: voucherErrorDetails ? undefined : ( voucher?.code ? { code: voucher.code } : undefined),
      serviceVoucher: serviceVoucher === '----' ? undefined : serviceVoucher,
      orderItems,
      customerInfo,
      payment: builtPayment,
      salesOffice,
      salesChannel,
      country,
      freeShipping: isServiceVoucherSelected,
      basketId,
      sourceChannel,
      preferredPaymentMethod: !existingCustomer ? preferredPaymentMethod : undefined,
      recreatedOriginalOrderId,
      deliveryAddress,
      reklaFlag
    });

    if (order === undefined) {
      yield put(showErrorNotification(translationKey));
      return;
    }
    if (!order.basketId) {
      const currentStateBasketId = yield select(orderEntrySelector.getBasketId);
      const deletedBasketSource = isBasketUsedPreviously(currentStateBasketId);
      yield put(logEvent({ message: `Basket ${currentStateBasketId} was already deleted after ${deletedBasketSource}`, level: LOG_LEVEL.WARN, err: {} }));
      if (deletedBasketSource) {
        yield put(showErrorNotification('Inaktive WILIS Sitzung. Bitte "Neue Sitzung" starten.'));
        return;
      }
    }

    if (phoneNumberRequired) {
      yield put(openModal(Modals.telephoneNotification));
      return;
    }

    if (areMethodsDisabled(availablePaymentMethods)) {
      yield put(openModal(Modals.takeToOnlineShop));
      return;
    }

    let response: CreateOrderResultResponse;
    if (existingCustomer) {
      response = yield call(apiGuestOrder.createLoggedInOrder, customer?.id, order);
    } else {
      response = yield call(apiGuestOrder.createGuestOrder, order);
    }
    yield put(deleteBasketSuccess());
    removeBasketId();
    trackCreatedOrderDeletedBasket(order.basketId);
    correctPackstationAddress(response);

    yield put(newOrderSuccess(response));
    removeReklaOrderInfoFromSession();
    yield put(openModal(country === CountryCode.ch ? Modals.CHOrderConfirmation : Modals.orderConfirmation));
    yield put(country === CountryCode.ch ? resetChEntryPayments() : resetPayment());

    // if the selected address is newly added we add flag usedInOrder
    if (customerInfo.selectedDeliveryAddress && !customerInfo.selectedDeliveryAddress.id) {
      const newDeliveryAddress = { ...customerInfo.selectedDeliveryAddress, usedInOrder: true };
      yield call(localCustomerStorage.updateLastNewDeliveryAddress, localCustomerId, newDeliveryAddress);
    }

    yield call(trackOrder);
  } catch (err) {
    yield put(newOrderError(err?.status));
    yield put(
      logEvent({
        level: LOG_LEVEL.WARN,
        message: `Couldn't create new order (basketId=${basketId})`,
        err: err,
      }),
    );
    let errMessage = extractDetails(err);
    if (err?.response?.data) {
      const responseData = camelizeKeys(err.response.data) as unknown as PaymentMethodNotAllowedErrorData;
      if (responseData.paymentOptions) {
        yield put(resetSelectedPaymentMethod());
        if (payment.selectedPaymentMethod === PaymentMethod.installments) {
          yield put(resetInstallmentPayment());
        }
        else if (payment.selectedPaymentMethod === PaymentMethod.installmentsFactoring) {
          yield put(resetInstallmentFactoringPayment());
        }
        if (areMethodsDisabled(responseData.paymentOptions)) {
          yield put(openModal(Modals.takeToOnlineShop));
        }
        yield put(setAvailablePaymentMethods(responseData.paymentOptions));
        errMessage = 'order.invalid.paymentMethod';
      }
    }

    yield put(showErrorNotification(errMessage));
  }
}

function correctPackstationAddress(orderResult: CreateOrderResultResponse) {
  if(orderResult.deliveryAddress.type === DeliveryAddressType.Packstation) {
    orderResult.deliveryAddress.packstationNumber = orderResult.deliveryAddress.packstation;
  }
}

export default function* newOrderWatcher() {
  yield takeEvery(requestNewOrder.type, newOrder);
  yield takeLatest(newOrderSuccess.type, setPreferredPaymentSaga);
  yield takeLatest(newOrderSuccess.type, checkAndUpdateMarketResearchConsentSaga);
  yield takeLatest(newOrderSuccess.type, handleGuestCustomerConsents);
}
