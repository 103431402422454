import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DEFAULT_SALES_OFFICE } from 'src/config/salesChannels';


export type SalesOfficeState = string;
export const salesOfficeInitialState: SalesOfficeState = DEFAULT_SALES_OFFICE;

const salesOfficeSlice = createSlice({  
  name: 'salesOffice',
  initialState: salesOfficeInitialState,
  reducers: {
    changeSalesOffice(_, { payload }: PayloadAction<SalesOfficeState>) {
      return payload;
    },
    setSalesOffice(_, { payload }: PayloadAction<SalesOfficeState>) {
      return payload;
    },
    resetSalesOffice() {
      return salesOfficeInitialState;
    },
  },
});

export const {
  changeSalesOffice,
  resetSalesOffice,
  setSalesOffice
} = salesOfficeSlice.actions;

export default salesOfficeSlice.reducer;