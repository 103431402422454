import salesChannels from 'src/config/salesChannels';
import { RootStateType } from 'src/constants/types';
import {
  TrackUserFailureLoginsEvent,
  TrackUserLoginEvent,
  TrackUserSessionEvent
} from 'src/redux/app/appSlice';
import { FetchCustomerResponse } from 'src/redux/customer/customerInfoSlice';
import {
  ChangeQuantityAction,
  AddOrderItemAction,
  DeleteOrderItemAction,
  TrackRemoveProductToCartEvent,
  TrackAddProductToCartEvent,
  TrackOrderEvent,
} from 'src/redux/order/orderEntrySlice';
import { TrackWaitListImpression, TrackWaitListReservation } from 'src/redux/product/createWaitlistItemSlice';
import {
  TrackOfferProductImpression,
  TrackProductClick,
  TrackProductDetailsViews,
  TrackProductRowImpression,
  TrackRowClick,
  TrackProductImpressionsEvent,
} from 'src/redux/product/productDetailsSlice';
import { FetchProductSearchResults } from 'src/redux/product/productSearchSlice';
import { TrackStockReminderPopupImpression, TrackStockReminderSetReminder } from 'src/redux/product/stockReminderSlice';
import { Dimension, DimensionKind } from 'src/types/offer/Basket';
import { DimensionType, VariantDimension } from 'src/types/product/dimensions';
import { Salesdriver, VariantValue } from 'src/types/product/product';
import toBaseProductNumber from 'src/utils/formatters/toBaseProductNumber';
import {
  getBrand,
  getDimensionss,
  getSalesDrivers,
} from 'src/utils/getProductImpressionView';
import { parseOrderItems } from 'src/utils/parseOrderItems';


const NOT_AVAILABLE = 'not available';
const MAIN_PRODUCT = 'Main Product';
const CROSS_SELL = 'Cross sell product';

export const orderConfirmationEvent = (_: {}, __: RootStateType, nextState: RootStateType) => ({
  event: 'order proccessed',
  order: nextState.order.newOrder.orderResponse,
});

export const changeQuantityEvent = (action: ChangeQuantityAction, prevState: RootStateType) => {
  const item = prevState.order.orderEntry.orderLineItems.find(i => i.id === action.payload.id);
  return {
    event: 'change quantity',
    orderLineItem: action.payload.id,
    quantiy: {
      oldValue: item?.quantity,
      newValue: action.payload.quantity,
    },
  };
};

export const addOrderItemToBasketEvent = (action: AddOrderItemAction) => {
  return {
    event: 'addOrderItemToBasket',
    productId: action.payload.sku,
  };
};

export const deleteOrderItemFromBasketEvent = (action: DeleteOrderItemAction) => {
  return {
    event: 'deleteOrderItemFromBasket',
    productId: action.payload.variant.sku,
  };
};

export const trackCustomerSearch = (action: FetchCustomerResponse) => {
  return {
    event: 'customer search',
    customerId: action.payload,
  };
};

export const trackProductSearch = (action: FetchProductSearchResults) => {
  return {
    event: 'product search',
    productSearchParams: action.payload,
  };
};

export const trackOrderEvent = (action: TrackOrderEvent) => {
  const cc = {
    event: 'eecPurchase',
    c_agentId: action.payload.agentUsername,
    ecommerce: {
      currencyCode: action.payload.orderTrack?.currency,
      purchase: {
        actionField: {
          id: action.payload.orderTrack.id,
          revenue: action.payload?.orderTrack?.totalPrice,
          shipping: action.payload.orderTrack?.delivery.price,
          coupon: action.payload.voucher?.code,
          distributionChannel:
            action.payload.salesChannel === '01'
              ? 'TV'
              : action.payload.salesChannel === '04'
                ? 'Internet'
                : 'other',
          c_cdt: action.payload.cdt,
        },
        products: parseOrderItems(action.payload.orderTrack.items, action.payload.voucher?.code, action.payload.salesChannel),
      },
    },
  };
  return cc;
};

export const trackAddProductToCart = (action: TrackAddProductToCartEvent) => {
  const { productDetails, salesChannel, sku, upsellsMainProduct } = action.payload;
  const dimensions: VariantDimension[] | undefined = productDetails?.variants.find(
    variant => variant.sku === sku,
  )?.dimensions;

  const actionFieldView = {
    list: productDetails?.crossSell?.leadProductSku ?
      `${toBaseProductNumber(productDetails.crossSell.leadProductSku)} | ${upsellsMainProduct ?? NOT_AVAILABLE}` :
      `${toBaseProductNumber(sku)} | ${productDetails?.name.long || NOT_AVAILABLE}`,
  };

  return {
    event: 'eecAddToCart',
    ecommerce: {
      add: {
        actionField: actionFieldView,
        products: [
          {
            name: `${toBaseProductNumber(sku)} | ${productDetails?.name?.long || NOT_AVAILABLE}`,
            id: toBaseProductNumber(sku),
            price: `${
              productDetails?.variants?.find(variant => variant?.sku === sku)?.price?.value ||
              NOT_AVAILABLE
            }`,
            list: actionFieldView.list,
            brand: productDetails?.brand?.brandName || NOT_AVAILABLE,
            category: productDetails?.categoryPath || NOT_AVAILABLE,
            variant: `color:${
              dimensions?.find(value => value.type === DimensionType.COLOR)?.value || NOT_AVAILABLE
            }|size:${
              dimensions?.find(value => value.type === DimensionType.SIZE)?.value || NOT_AVAILABLE
            }`,
            quantity: 1,
            dimension10: sku,
            dimension2: productDetails?.crossSell ? CROSS_SELL : MAIN_PRODUCT,
            dimension8: productDetails?.salesdrivers?.length
              ? productDetails.salesdrivers[0].name
              : NOT_AVAILABLE,
            dimension9:
              productDetails?.variants.find(variant => variant.sku === sku)?.price.label ||
              NOT_AVAILABLE,
            dimension12: salesChannel,
          },
        ],
      },
    },
  };
};

export const trackUserLogins = (action: TrackUserLoginEvent) => {
  return {
    event: 'login',
    c_agentId: action.payload.c_agentId,
    login_method: action.payload.loginMethod,
    login_verification_option: action.payload.loginVerificationOption,
  };
};

export const trackUserFailureLogins = (action: TrackUserFailureLoginsEvent) => {
  return {
    event: 'login_error',
    c_agentId: action.payload.c_agentId,
    login_method: action.payload.loginMethod,
    login_verification_option: action.payload.loginVerificationOption,
    error_message: action.payload.errorMessage,
    error_context: action.payload.errorContext
  };
};

export const trackUserSessions = (action: TrackUserSessionEvent) => {
  return {
    event: 'pageImpression',
    c_agentId: action.payload,
  };
};

export const trackProductClickEvent = (action: TrackProductClick) => {
  const { source, product, position, channel } = action.payload;
  const defaultVariant = product.variants.find((variant: VariantValue) => variant.sku === product.defaultVariantSku);

  return {
    event: 'eecProductClick',
    ecommerce: {
      click: {
        actionField: {
          list: source, // Name of the Main product or TV or Top Deals list name
        },
        products: [
          {
            name: `${toBaseProductNumber(product.baseProductNo)} | ${product.name.long}`,
            id: product.baseProductNo,
            price: product.variants[0].price.value,
            list: source,
            brand: product.brand?.brandName || '',
            category: product.categoryPath || NOT_AVAILABLE,
            variant: defaultVariant?.dimensions
              ?.map(dimension => `${dimension.type}:${dimension.value}`)
              .join('|') || NOT_AVAILABLE,
            position: Number(position) || position,
            dimension10: product.baseProductNo,
            dimension2: product?.crossSell ? CROSS_SELL : MAIN_PRODUCT,
            dimension8: product.salesdrivers?.length
              ? product.salesdrivers?.map((salesdriver: Salesdriver) => `${salesdriver.typeCode}`).join('|')
              : NOT_AVAILABLE,
            dimension9: product.variants[0].price.label || NOT_AVAILABLE,
            dimension12: channel,
          },
        ],
      },
    },
  };
};

export const trackRowClickEvent = (action: TrackRowClick) => {
  const { source, row, channel } = action.payload;
  return {
    event: 'eecProductClick',
    ecommerce: {
      click: {
        actionField: {
          list: source, // Name of the Main product or TV or Top Deals list name
        },
        products: [
          {
            name: `${toBaseProductNumber(row.bnr)} | ${row.name}`,
            id: row.bnr,
            price: row.price,
            list: source,
            brand: row.brand || '',
            category: row.category?.map(category => category.map(cat => cat).join('/')).join('/') || NOT_AVAILABLE,
            variant: row.variant || NOT_AVAILABLE,
            position: 1,
            dimension10: row.bnr,
            dimension2: MAIN_PRODUCT,
            dimension8: row.salesdrivers?.length
              ? row.salesdrivers?.map((salesdriver: string) => `${salesdriver}`).join('|')
              : NOT_AVAILABLE,
            dimension9: row.priceLabel || NOT_AVAILABLE,
            dimension12: channel,
          },
        ],
      },
    },
  };
};

export const trackRemoveProductFromCart = (action: TrackRemoveProductToCartEvent) => {
  const { productDetail, salesChannel } = action.payload;
  const sku = productDetail.sku ?? productDetail.baseProductNo;
  const dimensions: Dimension[] | undefined = productDetail.variant.dimensions;
  return {
    event: 'eecRemoveFromCart',
    ecommerce: {
      remove: {
        products: [
          {
            name: `${toBaseProductNumber(productDetail.baseProductNo)} | ${productDetail.name.long}`,
            id: productDetail.baseProductNo,
            price: `${productDetail.variant.price.value || NOT_AVAILABLE}`,
            list: `${toBaseProductNumber(productDetail.baseProductNo)} | ${productDetail.name.long}`,
            brand: productDetail.brand?.brandName || NOT_AVAILABLE,
            category: productDetail?.categoryPath || NOT_AVAILABLE,
            variant: `color:${
              dimensions?.find(value => value.type === DimensionKind.color)?.value || NOT_AVAILABLE
            }|size:${
              dimensions?.find(value => value.type === DimensionKind.size)?.value || NOT_AVAILABLE
            }`,
            quantity: 1,
            dimension10: sku,
            dimension2: productDetail?.crossSell ? CROSS_SELL : MAIN_PRODUCT,
            dimension8: NOT_AVAILABLE,
            dimension9: NOT_AVAILABLE,
            dimension12: salesChannel,
          },
        ],
      },
    },
  };
};

export const trackProductImpressionsEvent = (action: TrackProductImpressionsEvent) => {
  return {
    event: 'eecProductImpression',
    ecommerce: {
      ...action.payload,
      impressions: action.payload.impressions.map(impression => ({
        ...impression,
        list: impression.list ?? impression.name
      }))
    },
  };
};

export const trackProductDetailsViewsEvent = (action: TrackProductDetailsViews) => {
  return {
    event: 'eecProductDetailPage',
    ecommerce: action.payload
  };
};

export const trackOfferProductImpressionEvent = (action: TrackOfferProductImpression) => {
  const offer = action.payload.product;
  const salesChannel = action.payload.salesChannel;
  return {
    event: 'eecProductImpression',
    ecommerce: {
      impressions: [{
        name: `${toBaseProductNumber(offer.baseProductNo)} | ${offer?.name.long || NOT_AVAILABLE}`,
        id: toBaseProductNumber(offer.baseProductNo),
        price: offer.variants[0].price.value,
        brand: getBrand(offer.brand) ?? NOT_AVAILABLE,
        category: offer.categoryPath || NOT_AVAILABLE,
        variant: getDimensionss(offer.variants[0].dimensions) || NOT_AVAILABLE,
        list: `${toBaseProductNumber(offer.baseProductNo)} | ${offer?.name.long || NOT_AVAILABLE}`,
        position: 0,
        dimension10: offer.variants[0].sku || toBaseProductNumber(offer.baseProductNo),
        dimension2: offer.crossSell ? CROSS_SELL : MAIN_PRODUCT,
        dimension8: getSalesDrivers(offer.salesdrivers) || NOT_AVAILABLE,
        dimension9: offer.variants[0].price.label || NOT_AVAILABLE,
        dimension12: salesChannels.find(channel => channel.id === salesChannel)?.name || 'Other',
      }],
    },
  };
};

export const trackProductRowImpressionEvent = (action: TrackProductRowImpression) => {
  const rows = action.payload.products;
  const salesChannel = action.payload.salesChannel;
  return {
    event: 'eecProductImpression',
    ecommerce: {
      impressions: rows.map(rowItem => {
        return {
          name: `${toBaseProductNumber(rowItem.bnr)} | ${rowItem.name || NOT_AVAILABLE}`,
          id: toBaseProductNumber(rowItem.bnr),
          price: rowItem.price,
          brand: getBrand(rowItem.brand) ?? NOT_AVAILABLE,
          category: rowItem.category?.map(cate => cate.map(cat => cat).join('/')).join('/') || NOT_AVAILABLE,
          variant: rowItem.variant || NOT_AVAILABLE,
          list: `${toBaseProductNumber(rowItem.bnr)} | ${rowItem.name || NOT_AVAILABLE}`,
          position: 0,
          dimension10: toBaseProductNumber(rowItem.bnr),
          dimension2: MAIN_PRODUCT,
          dimension8: rowItem.salesdrivers?.length
            ? rowItem.salesdrivers?.map((salesdriver: string) => `${salesdriver}`).join('|')
            : NOT_AVAILABLE,
          dimension9: rowItem.priceLabel || NOT_AVAILABLE,
          dimension12: salesChannels.find(channel => channel.id === salesChannel)?.name || 'Other',
        };
      }),
    },
  };
};

export const trackWaitListImpressionEvent = (action: TrackWaitListImpression) => {
  return {
    event: 'waitlist',
    eventCategory: action.payload.eventCategory,
    eventAction: action.payload.eventAction,
    eventLabel: action.payload.eventLabel,
    brandName: action.payload.brandName,
    divisionName: action.payload.divisionName,
  };
};

export const trackWaitListReservationEvent = (action: TrackWaitListReservation) => {
  return {
    event: 'waitlist',
    eventCategory: action.payload.eventCategory,
    eventAction: action.payload.eventAction,
    eventLabel: action.payload.eventLabel,
    eventValue: action.payload.eventValue,
    brandName: action.payload.brandName,
    divisionName: action.payload.divisionName,
  };
};

export const trackStockReminderPopupImpressionEvent = (action: TrackStockReminderPopupImpression) => {
  return {
    event: 'stockreminder',
    eventCategory: action.payload.eventCategory,
    eventAction: action.payload.eventAction,
    eventLabel: action.payload.eventLabel,
    brandName: action.payload.brandName,
    divisionName: action.payload.divisionName,
  };
};

export const trackStockReminderSetReminderEvent = (action: TrackStockReminderSetReminder) => {
  return {
    event: 'stockreminder',
    eventCategory: action.payload.eventCategory,
    eventAction: action.payload.eventAction,
    eventLabel: action.payload.eventLabel,
    eventValue: action.payload.eventValue,
    brandName: action.payload.brandName,
    divisionName: action.payload.divisionName,
  };
};
