import { 
  call,
  getContext,
  put,
  takeLatest
} from 'redux-saga/effects';

import { ApiAddress } from 'src/api/apiAddress';
import {
  CustomerInfoActionTypes,
  setDhlPostNumberHasError,
  VerifyDhlPostNumber,
  resetDhlPostNumberVerificationState,
} from 'src/redux/customer/customerInfoSlice';
import { SagaContextItem } from 'src/store/ReduxSagaContext';
import logErrorAndShowNotification from 'src/utils/logErrorAndShowNotification';


export function* verifyDhlPostNumberSaga(action: VerifyDhlPostNumber) {
  try {
    const apiAddress: ApiAddress = yield getContext(SagaContextItem.apiAddress);
    
    const result = yield call(apiAddress.verifyDhlPostNumber, action.payload);

    if (result.valid) {
      yield put(resetDhlPostNumberVerificationState());
    } else {
      yield put(setDhlPostNumberHasError());
    }

  } catch (e) {
    yield put(setDhlPostNumberHasError());
    yield call(
      logErrorAndShowNotification,
      'Could not verify DHL post number',
      'customer.verifyDhlPostNumber.error',
      e
    );
  }
}

export default function* verifyDhlPostNumberWatcher() {
  yield takeLatest(CustomerInfoActionTypes.VERIFY_DHL_POST_NUMBER, verifyDhlPostNumberSaga);
}
