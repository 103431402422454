export default {
  'order.complete' : 'Complete order',
  'order.confirmation.title': 'Order successful',
  'order.basketIsEmpty': 'the product list is empty',
  'order.stockWarning': 'customize the products',
  'order.chosePaymentMethod': 'Chose a payment method',
  'order.enterCustomerData': 'Enter customer data',
  'order.choseDeliveryAddress': 'Chose delivery address',
  'order.changeInstallmentPlan': 'Change installment plan',
  'order.choosePaymentMethod': 'Choose payment method',
  'order.CustomerWithoutEmail': 'Customer should have email',
  'order.confirmation.description.text': 'thank you for your order',
  'order.confirmation.description.deliveryAddress': 'As always, we deliver to the delivery address provided.',
  'order.confirmation.description.orderId': 'BNR',
  'order.confirmation.description.deliveryTime': 'The delivery time is',
  'order.confirmation.description.deliveryTime.workingDays': 'working days.',
  'order.confirmation.description.deliveryTime.delay': ' It can sometimes take a little longer at the moment.',
  'order.confirmation.description.installmentSEPANotice': ' You will shortly receive a SEPA mandate for direct debit, which you can revoke at any time. We will send you your installment plan separately.',
  'order.confirmation.description.totalInInstallments': 'The total amount of {total} will be debited from your bank account in {installmentCount}.',
  'order.confirmation.description.email': 'We have sent you an order confirmation with all details to your email address.',
  'order.confirmation.description.regards': 'I wish you a nice day. Speak to you soon!',
  'order.confirmation.message.INVOICE': 'You transfer the invoice amount yourself.',
  'order.confirmation.message.DIRECT_DEBIT': 'We will debit the invoice amount from your bank account <b>5 days after dispatch of the goods</b>.',
  'order.confirmation.message.CASH_ON_DELIVERY': 'You pay the invoice amount cash on delivery upon receipt of the goods.',
  'order.confirmation.message.DE_CASH_ON_DELIVERY': 'Please have an additional 5,99 euros ready as a cash on delivery fee.',
  'order.confirmation.message.AT_CASH_ON_DELIVERY': 'Please have an additional 4,50 euros ready as a cash on delivery fee.',
  'order.close': 'Close order',
  'order.confirmation.takeBack' : 'Collection of old electrical appliances',
  'order.confirmation.mandat.directdebit': 'Sie erhalten in Kürze ein SEPA-Mandat für Ihren Bankeinzug. Bitte prüfen Sie die darin enthaltenen Angaben. Sie können Ihr SEPA-Mandat jederzeit widerrufen, ein Anruf genügt.',

  'order.payment.required': 'Payment method required',
  'order.create.error': 'Cannot create the order, please try again',
  'order.invalid.dateOfBirth': 'Invalid data of birth',
  'order.invalid.paymentMethod': 'Invalid payment method',
  'order.invalid.order': 'Invalid order',
  'order.invalid.billingAddress': 'Invalid Billing Address',
  'order.invalid.customerData': 'Invalid Customer Data',
  'order.cart.isEmpty': 'Your cart is empty',

  'order.summary.subtotal': 'Subotal',
  'order.summary.total': 'Total',
  'order.summary.saving': 'Saving',
  'order.summary.shipping': 'Shipping',
  'order.summary.voucher': 'Voucher',

  'order.voucher.error': 'Invalid voucher',
  'order.voucher.submited': 'Voucher submited',
  'order.voucher.redeemed': ' redeemed',
  'order.voucher.redeemedWithCode': ' Voucher "{VOUCHER_CODE}" redeemed.',
  'order.voucher.restrictedVoucherRedeemed': ' Voucher "{VOUCHER_CODE}" redeemed for {RESTRICTIONS}.',
  'order.voucher.freeShippingVoucherReddemed': 'Voucher {VOUCHER_CODE} redeemed (free shipping)',
  'order.voucher.eligibleProducts': 'eligible Products in Cart',
  'order.voucher.CATEGORY': 'Category',
  'order.voucher.PRODUCT_PRICE_LABEL': 'Preis-Label',
  'order.voucher.BRAND': 'Brand',

  'order.limit': 'Can\'t add more than 9 items',

  // ORDER_RESTRICTION
  'order.orderRestriction.CREDIT_LIMIT': 'Credit limit',
  'order.orderRestriction.DUNNING_LEVEL_1': 'Dunning level 1',
  'order.orderRestriction.CREDIT_CARD': 'credit card',
  'order.orderRestriction.INFODATA_INQUIRY_4': 'Infodata inquiry 4',
  'order.orderRestriction.NEGATIVE_INQUIRY': 'Negative inquiry',
  'order.orderRestriction.FINANCING': 'Financing',
  'order.orderRestriction.INFODATA_CREDIT_LIMIT': 'infodata credit limit',
  'order.orderRestriction.BIG_INQUIRY': 'big inquiry',
  'order.orderRestriction.INFODATA_INQUIRY_9': 'infodata inquiry9',
  'order.orderRestriction.ADDRESS_CHECK_MISSING': 'address check missing',
  'order.orderRestriction.RESERVATION': 'reservation',
  'order.orderRestriction.PAYMENT_LIMIT_MODAL_TITLE': 'Order Non Complete',
  'order.orderRestriction.PAYMENT_LIMIT_MODAL_MESSAGE': 'This customer can’t order with price more than {amount} and utilising {payment_method}. \n\n Please try with changing payment method or reduce the amount to complete the order with successfully.',
  'order.orderRestriction.FREQUENT_RETURNER': 'frequent returner',
  'order.orderRestriction.ACCOUNT_NUMBER_VALIDATED': 'account number validated',
  'order.orderRestriction.DUBIOUS_BANK_ACCOUNTS': 'dubious bank accounts',
  'order.orderRestriction.NON_PAYER_KSV': 'Non-payer KSV',
  'order.orderRestriction.NON_PAYER_REAL': 'Non-payer Real',
  'order.orderRestriction.NON_PAYER_KSP': 'Non-payer KSP',
  'order.orderRestriction.DEPARTED_CREATOR': 'Creator retired',
  'order.orderRestriction.LS18': 'Mögliche Kundendublette',
  'order.orderRestriction.LS_INTERN': 'LS intern',
  'order.orderRestriction.DUPLICATE': 'duplicate',
  'order.orderRestriction.PERMANANT_RETURNER': 'permanant returner',
  'order.orderRestriction.NON_PAYERU': 'non payeru',
  'order.orderRestriction.FRAUD': 'fraud',
  'order.orderRestriction.CUSTOMER_REQUIREMENT': 'customer requirement',
  'order.orderRestriction.PRANK_ORDER': 'prank order',
  'order.orderRestriction.SAP_LEGACY_DATA': 'sap legacy data',
  'order.orderRestriction.DECEASED': 'deceased',
  'order.orderRestriction.UNKNOWN_ADDRESS': 'unknown address',
  'order.orderRestriction.UNDERAGED': 'underaged',
  'order.orderRestriction.DISABILITY': 'disability',
  'order.orderRestriction.DRM_LOCK': 'drm lock',
  'order.orderRestriction.CUSTOMER_TO_BE_DELETED': 'customer to be deleted',
  'order.orderRestriction.AS_INTERNAL': 'as internal',
  'order.orderRestriction.SUBJECT_TO_LAWYER': 'subject to lawyer',
  'order.orderRestriction.INSOLVENCY': 'insolvency',
  'order.orderRestriction.DEPARTED_EMPLOYEE': 'departed employee',
  'order.orderRestriction.SEE_MASTER_DATA': 'see master data',
  'order.orderRestriction.DATA_PRIVACY_LOCK': 'data privacy lock',
  'order.orderRestriction.DELETION_GDPR': 'deletion gdpr',
  'order.orderRestriction.TO_BE_ARCHIVED': 'to be archived',
  'order.orderRestriction.ARCHIVED': 'Archived',
  'order.orderRestriction.NON_PAYER_INFOSCORE': 'sperre Nichtzahler ',
  'order.orderRestriction.SIEBEL_APPROVED': 'siebel approved',
  'order.orderRestriction.TEMPORARY_DELIVERY_BLOCK': 'Temporary delivery block',

  'customerSearch.orderRestriction.CREDIT_LIMIT': 'Kreditlimit',
  'customerSearch.orderRestriction.DUNNING_LEVEL_1': 'Mahnstufe 1',
  'customerSearch.orderRestriction.CREDIT_CARD': 'Kreditkarte',
  'customerSearch.orderRestriction.INFODATA_INQUIRY_4': 'INFODATA_INQUIRY_4',
  'customerSearch.orderRestriction.NEGATIVE_INQUIRY': 'Negativauskunft',
  'customerSearch.orderRestriction.FINANCING': 'Finanzierung',
  'customerSearch.orderRestriction.INFODATA_CREDIT_LIMIT': 'Infodata und Kreditlimit',
  'customerSearch.orderRestriction.BIG_INQUIRY': 'Großauskunft',
  'customerSearch.orderRestriction.INFODATA_INQUIRY_9': 'Infodata Auskunft',
  'customerSearch.orderRestriction.ADDRESS_CHECK_MISSING': 'Adressprüfung fehlt',
  'customerSearch.orderRestriction.RESERVATION': 'Reservierung',
  'customerSearch.orderRestriction.PAYMENT_LIMIT_MODAL_TITLE': 'Bitte Bestellung prüfen',
  'customerSearch.orderRestriction.PAYMENT_LIMIT_MODAL_MESSAGE': 'Das Bestelllimit bei {payment_method} ist {amount}. \n\n Bitte versuchen Sie mit einer anderen Zahlungsmethode oder reduzieren Sie die Bestellsumme.',
  'customerSearch.orderRestriction.FREQUENT_RETURNER': 'Hochretournierer',
  'customerSearch.orderRestriction.ACCOUNT_NUMBER_VALIDATED': 'Kontonummer validiert (15)',
  'customerSearch.orderRestriction.DUBIOUS_BANK_ACCOUNTS': 'Dubiose Bankdaten',
  'customerSearch.orderRestriction.NON_PAYER_KSV': 'Nichtzahler KSV',
  'customerSearch.orderRestriction.NON_PAYER_REAL': 'Nichtzahler Real',
  'customerSearch.orderRestriction.NON_PAYER_KSP': 'Nichtzahler KSP',
  'customerSearch.orderRestriction.DEPARTED_CREATOR': 'Creator ausgeschieden',
  'customerSearch.orderRestriction.LS18': 'Mögliche Kundendublette',
  'customerSearch.orderRestriction.LS_INTERN': 'LS intern',
  'customerSearch.orderRestriction.DUPLICATE': 'Dublette/Datenzusammenführung',
  'customerSearch.orderRestriction.PERMANANT_RETURNER': 'Hochretournierer',
  'customerSearch.orderRestriction.NON_PAYERU': 'Nichtzahler',
  'customerSearch.orderRestriction.FRAUD': 'Versandhandelbetrüger',
  'customerSearch.orderRestriction.CUSTOMER_REQUIREMENT': 'Bestellung nicht möglich (Auftragsbeschränkung auf Kundenwunsch) - Kunde kann sich zur Freischaltung schriftlich an HSE24 wenden',
  'customerSearch.orderRestriction.PRANK_ORDER': 'Sperre Juxbesteller',
  'customerSearch.orderRestriction.SAP_LEGACY_DATA': 'Altdaten vor SAP',
  'customerSearch.orderRestriction.DECEASED': 'Verstorben',
  'customerSearch.orderRestriction.UNKNOWN_ADDRESS': 'Adresse unbekannt',
  'customerSearch.orderRestriction.UNDERAGED': 'Minderjährig',
  'customerSearch.orderRestriction.DISABILITY': 'Unmündig',
  'customerSearch.orderRestriction.DRM_LOCK': 'DRM Sperre',
  'customerSearch.orderRestriction.CUSTOMER_TO_BE_DELETED': 'Kunde wird gelöscht',
  'customerSearch.orderRestriction.AS_INTERNAL': 'AS intern',
  'customerSearch.orderRestriction.SUBJECT_TO_LAWYER': 'An Anwalt abgegeben',
  'customerSearch.orderRestriction.INSOLVENCY': 'Raten/Insolvenz/Konkurs',
  'customerSearch.orderRestriction.DEPARTED_EMPLOYEE': 'Mitarbeiter ausgeschieden',
  'customerSearch.orderRestriction.SEE_MASTER_DATA': 's. Bemerkung Stammsatz',
  'customerSearch.orderRestriction.DATA_PRIVACY_LOCK': 'Datenschutzsperrung',
  'customerSearch.orderRestriction.DELETION_GDPR': 'Löschung DSGVO',
  'customerSearch.orderRestriction.TO_BE_ARCHIVED': 'Archivierung geplant',
  'customerSearch.orderRestriction.ARCHIVED': 'Archiviert (EOB)',
  'customerSearch.orderRestriction.NON_PAYER_INFOSCORE': 'sperre Nichtzahler ',
  'customerSearch.orderRestriction.SIEBEL_APPROVED': 'Freigegeben Siebel',
  'customerSearch.orderRestriction.TEMPORARY_DELIVERY_BLOCK': 'Temporäre Liefersperre',

  'order.orderCanceled': 'cancellation in progress',

  'order.edit.items': 'Positionen',
  'order.edit.cancellationReason': 'Einen Stornogrund auswählen',
  'order.edit.cancelOrderTitle': 'Die gesamte Bestellung stornieren',
  'order.edit.cancelPositionTitle': 'Position stornieren',
  'order.edit.orderCancelReason': 'Warum möchten Sie die gesamte Bestellung stornieren?',
  'order.edit.positionCancelReason': 'Warum möchten Sie die Produkte stornieren?',
  'order.edit.cancelOrder': 'Bestellung stornieren',
  'order.edit.cancelPosition': 'Position stornieren',
  'order.edit.cancelPosition.txtBtn': 'Stornieren',
  'order.edit.removePosition.txtBtn': 'Löschen',
  'order.edit.positionCancellationReason': 'Stornogrund',
  'order.edit.changeSaved': 'Die Änderung ist gespeichert!',
  'order.edit.changeSavedMsg': 'Die Änderung wird auf Ihrer Bestellung aktualisiert.',
  'order.edit.regards': 'Ich wünsche Ihnen noch einen schönen Tag. Auf Wiederhören!',
  'order.edit.positionCancelled': 'Die Position wurde storniert',
  'order.edit.positionCancelledWithReason': 'The position was cancelled. Reason {reason}',
  'order.edit.positionReturned' : 'The position was returned',
  'order.edit.orderCancelled': 'Die gesamte Bestellung wurde storniert!',
  'order.edit.orderCancelledMsg': 'Ihrer gesamte Bestellung wurde storniert.',
  'order.edit.productAdded': 'Produkt erfolgreich als neue Position hinzugefügt',

  'order.edit.deliveryStatus.label':'Lieferstatus: ',
  'order.edit.deliveryStatus.value':'In Bearbeitung ({date})',
  'order.edit.deliveryAddress.title':'Lieferadresse: ',
  'order.edit.deliveryAddress.multipleDeliveryPosition':'Lieferadressen stehen in den Positionen',
  'order.edit.deliveryAddress.setPreferred':'Als bevorzugte Lieferadresse markieren',
  'order.edit.deliveryAddress.originalDeliveryAddress':'Identisch mit Rechnungsadresse',
  'order.edit.deliveryAddress.Country.DE':'Deutschland',
  'order.edit.deliveryAddress.Country.AT':'Österreich',
  'order.edit.deliveryAddress.Country.CH':'Schweiz',
  'order.edit.deliveryAddress.Country.undefined':'-',
  'order.edit.deliveryAddress.fromBillingAddress':'Identisch mit Rechnungsadresse',
  'order.edit.deliveryAddress.addNewAddress':'Add a new delivery address',
  'order.edit.deliveryAddress.type.POSTAL':'Postanschrift ',
  'order.edit.deliveryAddress.type.PACKSTATION':'Packstation ',
  'order.edit.deliveryAddress.maxAllowedAddressesReached':'Max Allowed addresses reached',
  'order.edit.addressNotSaved': 'Diese Lieferadresse wird nicht im Kundenkonto gespeichert',
  'order.edit.postNumber': 'Postnummer',
  'order.edit.packstation': 'Packstation',
  'order.edit.saveChanges' : 'Save changes',

  'order.edit.DeliveryAddressChangeConfirmation.title': 'Lieferadresse',
  'order.edit.DeliveryAddressChangeConfirmation.description': 'Wenn Sie die Lieferadresse bearbeiten, wird die ausgewählte Lieferadresse für alle Positionen verwendet.',
  'order.edit.DeliveryAddressChangeConfirmation.Abort': 'Abbrechen',
  'order.edit.DeliveryAddressChangeConfirmation.EditDeliveryAddress': 'Lieferadresse bearbeiten',
  'order.edit.cashNotAllowed': 'Nachnahmebestellungen liefern wir ausschließlich an die Rechnungsadresse. Möchten Sie eine abweichende ' +
  'Lieferadresse nutzen, dann wählen Sie bitte eine andere Zahlungsweise aus.',
  'order.edit.installementNotAllowed.PACKSTATION': 'Wenn Sie die Lieferung an eine Packstation wünschen, dann wählen Sie bitte eine andere Zahlungsart aus.',
  'order.edit.installementNotAllowed.POSTFILIALE': 'Wenn Sie die Lieferung an eine Postfiliale wünschen, dann wählen Sie bitte eine andere Zahlungsart aus.',
  'order.edit.installementNotAllowed.PAKETSHOP': 'Wenn Sie die Lieferung an eine Paketshop wünschen, dann wählen Sie bitte eine andere Zahlungsart aus.',
  'order.edit.installementNotAllowed.POSTSTATION': 'Wenn Sie die Lieferung an eine Poststation wünschen, dann wählen Sie bitte eine andere Zahlungsart aus.',
  'order.edit.voucher.redeemed': '{VALUE} {CURRENCY} Gutschein {CODE} wird eingelöst',
  'order.edit.itemVoucher.redeemed': '-{VALUE}{CURRENCY} voucher {CODE}',
  'order.edit.remove': 'Löschen',
  'order.edit.redeem': 'Einlösen',
  'order.edit.installmentLimit': 'Ihr Bestellwert erreicht nicht den Mindestbestellwert: 45€ für diese Zahlungsart.',
  'order.edit.updateInstallment': 'Wegen des neuen Gesamtbetrags sind die Monatsraten nicht mehr gültig. Klick auf „ändern“, um den Ratenplan zu aktualisieren.',
  'order.edit.installment&VoucherNotAllowed': 'Wenn Sie einen Gutschein einlösen wollen, wählen Sie bitte eine andere Zahlungsart aus.',
  'order.edit.disableVoucherInstallment': 'Bei einer Ratenzahlung kann kein Gutschein eingelöst werden',
  'order.employeeDiscountHint': 'Rabatt wird abgezogen',
  'order.percentageMaxDiscountHint': '. We have calculated the maximum discount value of {discount_value} {currency} for you.',
  'order.employeeDiscountHint.categoryExcluded': 'Rabatt kann nicht auf {category} abgezogen werden',
  'order.takeBackMessage': 'Would you like to have an old electrical device (with the same functions) picked up free of charge?',
  'order.takeBackCheckBox': 'Collect old electrical appliances',
  'order.learnMore': 'Learn more',

  'order.edit.search.deny.hint':'Bei unterschiedlichen Lieferadressen kann keine neue Position hinzugefügt werden. Bitte neue Bestellung aufnehmen.',
  'order.upgradeMessage': '„I recommend our special set where you save {DISCOUNT} today? This also includes {NAME}.“',
  'order.upgradedProduct': 'Upgraded Produkt',
  'order.edit.displayUpgradeVariants': 'Display variants',
  'order.edit.upgrade': 'Upgrade',
  'order.itemQuantityWarning': 'Only {MAX} pieces are available. Please inform the customer and reduce the amount.',
  'order.takeBack.learnMoreTitle': 'Collection of old electrical appliances',
  'order.takeBack.learnMoreDescription': '“We will be happy to collect your old electrical device from you free of charge if it essentially fulfills the same functions as the new device from HSE. Order z. For example, a cross-trainer, you can have your old cross-trainer disposed of (but not your treadmill). The collection is carried out by DHL. You will receive further information in the next few days by telephone (or in writing if we cannot reach you). "',
  'order.takeBack.close': 'Close',
  'order.takeBack.detailsCollection': 'Contact details for the collection of old electrical appliances',
  'order.takeBack.detailsCollectionHint': '„You will receive further information on the process of collecting old electrical appliances from us in the next few days. Would you like to be contacted by phone or email?”',
  'order.takeBack.detailsCollectionHintTelephone': '„You will receive further information on the process of collecting old electrical appliances by telephone in the next few days. Please give me your telephone number to compare.”',
  'order.takeBack.detailsCollectionHintEmail': '„You will receive further information on the process of collecting old electrical appliances by email in the next few days. Please give me your email address to compare.”',
  'order.takeBack.detailsCollectionEmailChangeHint': 'If the e-mail address is outdated or incorrect, please refer the customer to the service line after placing the order (Tel .: 0800 23 414 74)',
  'order.takeBack.confirmEditedOrder': 'Confirm and save all changes',
  'order.takeBack.confirmOrder': 'Confirm and complete the order',

  'order.subscriptionSellable.notSupported':'Please switch to KIK to create a loyalty subscription product',
  'order.check.redeem': 'Eingelöste Gutscheine prüfen',

  'order.discountHint': 'Gutschein wird eingelöst',
  'order.edit.payment.edit.blocked': 'Wartungsarbeiten. Zahlungsartenwechsel in Ratenzahlung derzeit nicht möglich.',

  'order.unsellableOrder.reasons.VARIANT_NOT_AVAILABLE': 'This product is unfortunately sold out. Please inform the customer and delete the product.',
  'order.unsellableOrder.reasons.SUBSCRIPTION_ONLY': 'This product is only available as a loyalty subscription.',
  'order.unsellableOrder.reasons.ERP_PRODUCT_NOT_IN_SORTIMENT': 'This product is currently not available.',
  'order.unsellableOrder.reasons.ERP_PRODUCT_NOT_RELEASED': 'This product is currently not available.',
  'order.unsellableOrder.reasons.OUT_OF_STOCK': 'This product is unfortunately sold out. Please inform the customer and delete the product.',
  'order.unsellableOrder.reasons.AVAILABLE_QUANTITY_EXCEEDED': 'Only {MAX} items are available. Please inform customer and reduce the quantity.',
  'order.unsellableOrder.reasons.PRICE_DATE_NOT_VALID': 'Price date is not valid.',
  'order.unsellableOrder.reasons.NOT_AVAILABLE_IN_COUNTRY': 'This product is not available in the selected country.',
  'order.unsellableOrder.reasons.NOT_RESERVED': 'This product is unfortunately sold out. Please inform the customer and delete the product.',
  'orderConfirmation.effectiveInterestRate': 'Effective interest rate {interestRate}%',
  'orderConfirmation.nominalInterestRate': 'Nominal rate {nominalInterestRate}%',
  'orderConfirmation.fundingAmount': 'Funding amount',

  'order.invalidInstallmentsPlans': 'Wegen des neuen Gesamtbetrags sind die Monatsraten nicht mehr gültig. Klicke auf "{paymentLabel}", um den Ratenplan zu aktualisieren.',
};
