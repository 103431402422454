export default {
  'customer.customerInfoTitle': 'Customer Info',
  'customer.accountId': 'Account ID',
  'customer.orderRestriction': 'Order Restriction',
  'customer.streetNumber': 'Street Number',
  'customer.zipCode': 'Postal Code',
  'customer.zipCodeShort': 'PLZ',
  'customer.city': 'cityity',
  'customer.addressAddition': 'Address addition',
  'customer.addressAdditionOptional': 'Address addition (optional)',
  'customer.locked': 'Customer locked',
  'customer.wrongCountry': 'Wrong customer country',
  'customer.wrongCountryMessage': 'You need to switch the country to choose this customer',
  'customer.createEdit.isDefault': 'Als bevorzugte Lieferadresse markieren',
  'customer.info.careOf': 'C/O',
  // Validation
  'customer.createEdit.validation.required.salutation': 'Salutation is a require field',
  'customer.createEdit.validation.required.firstName': 'First name is a required field',
  'customer.createEdit.validation.required.lastName': 'Last name is a required field',
  'customer.createEdit.validation.required.phoneNumber': 'Enter a valid phone number',
  'customer.createEdit.validation.required.street': 'Street is a required field',
  'customer.createEdit.validation.required.streetNumber': 'Street Number is a required field',
  'customer.createEdit.validation.required.zipCode': 'Zipcode is a required field',
  'customer.createEdit.validation.required.city': 'City is a required field',
  // Advanced Search
  'customer.advancedSearch.customerNbr': 'KDNR',
  'customer.advancedSearch.postalCode': 'PLZ',
  'customer.advancedSearch.dateOfBirth': 'Geb.Datum',
  'customer.advancedSearch.link': 'Advanced search',
  'customer.advancedSearch.title': 'Customer - Advanced Search',
  'customer.advancedSearch.result': 'Search results',
  'customer.advancedSearch.minFields': 'At least 3 fields must be set',
  'customer.advancedSearch.foundTooMuchCustomers': 'Mehr als 10 Treffer. Bitte weitere Suchkriterien hinzufügen.',
  'customer.firstName.minCaract': 'The first name must consist of at least 1 character.',
  'customer.lastName.minCaract': 'The last name must consist of at least 1 character.',
  'customer.careOf.minCaract': 'The C/O must consist of more than 1 character.',
  'customer.firstName.validValue': 'Please check first name',
  'customer.phoneNumber.validValue': 'Please check phone number',
  'customer.email.validValue': 'Please check email',
  'customer.lastName.validValue': 'Please check last name',
  'customer.streetNumber.validValue': 'Please check house number',
  'customer.streetNumber.startWithNumber': 'House number must start with a number/no spaces.',
  'customer.street.validValue': 'Please check street',
  'customer.zipCode.validValue': 'Please check zip code',
  'customer.city.validValue': 'Please check city',
  'customer.notFound': 'Customer not found',
  'customer.somethingWrong': 'Mindestens drei Felder befüllen.',
  'customer.salutation.required': 'Please check salutation',
  'customer.dateOfBirth.required': 'Please check birth date',
  'customer.phoneNumber.required': 'Please check phone number',
  'customer.email.required': 'Please check email',
  'customer.street.required': 'Please check street',
  'customer.street.noPackstation': 'Packstation cannot be used as a billing address',
  'no invalid characters': 'No invalid characters',
  'customer.moreThan10Customers': 'More than 10 customers are found in the database, you must add more filters',
  'customer.messginInformation': 'Missing Information',
  'customer.phoneNumberMessing': 'Freight forwarding goods: Please enter phone number for delivery agreement.',
  'customer.EMPLOYEE': 'HSE Mitarbeiter',
  'customer.PARTNER': 'Partner / Journalist',
  'customer.CREATOR': 'HSE Creator',
  'customer.TV_CREATOR': 'TV Creator',
  'customer.INFLUENCER': 'Influencer',
  'customer.discount.voucher.EMPLOYEE': 'Gutscheineinlösung für HSE Mitarbeiter nicht möglich',
  'customer.discount.voucher.PARTNER': 'Gutscheineinlösung für Partner / Journalist nicht möglich',
  'customer.discount.voucher.CREATOR': 'Gutscheineinlösung für Creator nicht möglich',
  'customer.discount.installment.EMPLOYEE': 'Ratenzahlung für HSE Mitarbeiter nicht möglich. Bitte wählen Sie eine andere Zahlungsart aus.',
  'customer.discount.installment.PARTNER': 'Ratenzahlung für Partner / Journalist nicht möglich. Bitte wählen Sie eine andere Zahlungsart aus.',
  'customer.discount.installment.CREATOR': 'Ratenzahlung für Creator nicht möglich. Bitte wählen Sie eine andere Zahlungsart aus.',

  'customer.blocked': 'Kunde gesperrt ',
  'customer.blockedMessage': 'Bestellung nicht möglich (Auftrag Beschränkung) - Kunde Kann sich bei Ruckfragen schriftlich an HSE24 wenden',
  'customer.blocked.duplicate': 'Dublette prüfen',

  'customer.duplicate.hint': '„Your data was found in an existing customer account. Please tell me your address for data comparison.”',
  'customer.duplicate.useExistingAccount': 'Use existing customer account',
  'customer.duplicate.accountDoesNotBelongToCustomer': 'The account does not belong to the customer',
  'customer.duplicated.email.wasFoundInExistingAccount': '„Your E-Mail address {email} was found in an existing customer account. Please tell me your address for data comparison.”',
  'customer.duplicate.email.createOrContinue': 'Create a new customer account with a different email address or continue without an email address',
  'customer.duplicate.email.back': 'Back to data entry',

  'customer.blocked.ex_employee.create': 'The customer is no longer an employee. Please create a new KDNR.',
  'customer.blocked.create': 'Create a new customer account',
  'customer.blocked.logout': 'Log out customer',
  'customer.blocked.auth': 'Please authenticate customer.',
  'customer.blocked.hintMessage.asks_why': 'When customer asks why:',
  'customer.blocked.hintMessage.asks_why_answer': 'customer.hintMessage.asks_why_answer',
  'customer.blocked.hintMessage.supportInfo': '"An order is not possible. Please contact our service colleagues on 0800 23 414 74. They can be reached from 8 a.m. to 9 p.m. ”',
  'customer.blocked.hintMessage.not_possible_to_order': '“Unfortunately, it is not possible to order. You are welcome to inquire in writing. "',
  'customer.blocked.hintMessage.postal_address_title': 'Postal address:',
  'customer.blocked.hintMessage.postal_address_value': 'HSE GmbH, Postfach 14 27, 85732 Ismaning',
  'customer.inputSearch': 'KDNR eingeben, Enter drücken',
  'customer.authentication': 'Authentifizierung',
  'customer.birthdayCheckText': 'To compare your data, please tell me your date of birth.',
  'customer.birthdayCheckText.incorrectPromptChoice': 'Please select the correct birthdate. Try again in {countdown} seconds.',
  'customer.nameAddressCheckText': '„Please tell me your name and address for data comparison.”',
  'customer.nameAddressCheckText.title': 'Date of birth is missing',
  'customer.nameAddressCheckText.birthdayText': '"We still don\'t have your date of birth in your customer account. I need this information to complete the order."{br}(We need it to ensure that nobody has unauthorized access to your account and to confirm that you are of legal age.)',
  'customer.nameAddressCheckText.submit': 'Save',
  'customer.nameAddressCheckText.abort': 'Cancel and log out customer',
  // pin texts
  'customer.pin.enter_pin': '„Bitte nennen Sie mir die {position} 3 Ziffern Ihrer Telefon-PIN zum Datenabgleich.“',
  'customer.pin.alternative_reasons': 'Alternativ 2: Grund fur Bearbeitung ohne Telefon-PIN erfassen',
  'customer.pin.login_reason.choose_reason': 'Wählen Sie einen Grund',
  'customer.pin.login_reason.NOT_ON_PHONE': 'KD nicht am Telefon - Backofficetätigkeit',
  'customer.pin.login_reason.IT_PROCESSING': 'IT-Bearbeitung',
  'customer.pin.login_reason.RECREATE_REJECTED_ORDER': 'Abgelehnte Bestellung neu erstellen',
  'customer.pin.wrong_pin': 'Wrong phone PIN ({attempts} out of 5 attempts). Please try again or ask the customer to send a new PIN.',
  'customer.pin.request_new_pin': 'Neue Telefon-PIN versenden?',
  'customer.pin.request_new_pin.address_message': 'Soll eine neue Telefon-PIN an die Meldeadresse versendet werden?',
  'customer.pin.yes': 'JA',
  'customer.pin.no': 'NEIN',
  'customer.pin.request_new_pin.success_message': 'Brief zur neuen Telefon-PIN wurde versendet.',
  'customer.pin.request_new_pin.fail_message': 'Korrektur der Meldeadresse über die Service-Hotline nötig.',
  'customer.pin.max_attempt_error_message': 'Die max. Anzahl an Fehlversuchen ist erreicht. Bitte KD fragen: „Wünschen Sie eine neue Telefon-PIN? Bis zum Erhalt können Sie gerne online bestellen.“',
  'customer.pin.already_reset': 'Neue Telefon-PIN wurde am {date} versendet. Sollte der Brief innerhalb von 5 Werktagen nicht ankommen, kann PIN erneut angefordert werden.',

  'customer.logIn': 'Einloggen',
  'customer.loginAccount': 'Login customer account',
  'customer.blocked.loginAccountsSubtitle': 'Please check the following customer accounts and log in the correct one.',
  'customer.newCustomer.welcome': 'Wenn Ihnen etwas nicht gefällt, haben Sie die Möglichkeit, es innerhalb {br}von 30 Tagen kostenfrei zurück zu senden. Sofern die Ware vollständig {br}ist und sich in einem neuwertigen Zustand befindet wie bei Erhalt. Den Retouren-Aufkleber finden Sie auf dem Lieferschein.{br}{br}' +
    'Übrigens zahlen Sie nur einmal am Tag 5,95 Euro Versandkosten. Wenn Sie {br}also heute noch etwas Tolles entdecken - rufen Sie uns gerne an - wir packen es zu Ihrer aktuellen Bestellung dazu.{br}',
  'customer.ZipCodeError': 'Do you mean {zip}, {city}? Please check the combination of postcode and city.',

  'customer.givenAddress': 'Adressdaten überprüft, mit angegebener Adresse fortfahren',
  'customer.lastTenCustomers': 'Last 10 Customers',
  'customer.new': 'New Customer',
  'customer.dateOfBirth': 'Geburtsdatum',
  'customer.emailAddress': 'E-Mail-Adersse',
  'customer.phoneNumber': 'Telefonnummer',

  'customer.blocked.reason.DUPLICATE': 'Dublette/Datenzusammenführung',
  'customer.blocked.reason.DELETION_GDPR': 'Dublette/Löschung DSGVO',
  'customer.blocked.reason.PERMANANT_RETURNER': 'Dauerretournierer',
  'customer.blocked.reason.CUSTOMER_REQUIREMENT': 'Kundenwunsch',
  'customer.blocked.reason.UNKNOWN_ADDRESS': 'Adresse unbekannt',
  'customer.blocked.reason.DRM_LOCK': 'DRM Sperre',
  'customer.blocked.reason.FRAUD': 'Versandhandelbetrüger',
  'customer.blocked.reason.DECEASED': 'Verstorben',
  'customer.blocked.reason.NON_PAYERU': 'Nichtzahler',
  'customer.blocked.reason.PRANK_ORDER': 'Juxbesteller',
  'customer.blocked.reason.UNDERAGED': 'Minderjährig',
  'customer.blocked.reason.DISABILITY': 'Unmündig',
  'customer.blocked.reason.AS_INTERNAL': 'AS intern',
  'customer.blocked.reason.DATA_PRIVACY_LOCK': 'Datenschutzsperrung',
  'customer.blocked.reason.ADDRESS_CHECK_MISSING': 'Adressprüfung fehlt',
  'customer.blocked.reason.DEPARTED_EMPLOYEE': 'MA ausgeschieden',
  'customer.blocked.reason.NON_PAYER_KSV': 'Non-payer KSV',
  'customer.blocked.reason.NON_PAYER_REAL': 'Non-payer Real',
  'customer.blocked.reason.NON_PAYER_KSP': 'Non-payer KSP',
  'customer.blocked.reason.NON_PAYER_INFOSCORE': 'Block non-payers',
  'customer.blocked.reason.DEPARTED_CREATOR': 'Creator retired',
  'customer.blocked.reason.ARCHIVED': 'Archiviert (EOB)',

  'customer.blocked.Deletion_GDPR.request_unlock': '„Your account has been blocked at your own request. Please send your request for unlocking, stating your customer number, to the following address:',
  'customer.blocked.Deletion_GDPR.de_address': 'Home Shopping Europe GmbH{br}Münchener Str. 101h{br}85737 Ismaning',
  'customer.blocked.Deletion_GDPR.at_address': 'HSE Austria{br}Service-Center c/o TEL24{br}Franz-Josef-Str. 15{br}5020 Salzburg',
  'customer.blocked.Deletion_GDPR.unblocking_hint': 'Unblocking is only possible by post.“',

  'waitlistHistory.openButton': 'waiting list',
  'subscriptionHistory.openButton': 'Treue-Abo',

  'customer.pinVerification.error_request': 'Sorry, we can\'t verify the PIN at the moment.',
  'customer.skipPinVerification.error_request': 'Sorry, we can\'t skip the PIN verification at the moment.',
  'customer.requestNewVerificationPin.error_request': 'Sorry, we can\'t request new customer PIN verification at the moment.',
  'customer.requestNewVerificationPin.alreadySent': 'Request for a new customer PIN already sent',
  'customer.bypassBirthdayCheck': 'continue without checking'
};
