import { FlagsType } from './featureFlags';


const wilisTeamAgentUsernames = [
  'NWERN',
  'DENWER',
  'DETSZEN',
  'DETSZENY',
  'KHALIL',
  'ABDE',
  'farajist',
  'elias.m',
  'ISMAIL',
  'nada',
  'ahmeds.mansouri',
  'youssef.bousetta',
  'SANDRA',
  'DEDAJOST',
  'daniela.jost@hse.com',
  'ines.eckart',
  'a.elayadi',
  'ABDE',
  'ABDERED',
  'A.Moog',
  'claudia.lemken',
  'ATTEST',
  'a.el_ayadi@hse24.de',
  'm.elias'
];

export enum Environment {
  DEVELOPMENT = 'development',
  STAGING = 'staging',
  PRODUCTION = 'production',
}

interface EnvVars {
  slackChannelAlarmsWebhook?: string;
}

interface Configuration extends EnvVars {
  environment: Environment;
  flags: FlagsType;
  rootRoute: string;
  backofficeUrl: string;
  orderServiceUrl: string;
  callCenterServiceUrl: string;
  customerServiceUrl: string;
  factFinderApiUrl: {
    de: string;
    at: string;
    ch: string;
  };
  productServiceUrl: {
    de: string;
    at: string;
    ch: string;
  };
  basketServiceAkamaiUrl: {
    de: string;
    at: string;
    ch: string,
    enabled?: boolean;
  };
  productServiceProxyUrl: string;
  stockHistoryServiceUrl: string;
  staticMediaUrl: string;
  addressCheckURL: string;
  basketURL: string;
  paymentServiceURL: string;
  orderHistoryServiceURL: string;
  searchUrl: {
    de: string;
    at: string;
    ch: string;
  };
  upsells: string;
  offers: {
    de: string;
    at: string;
    ch: string,
  };
  virtualVoucherApiUrl: string;
  osVoucherServiceApiUrl: string;
  cognito: {
    url: string;
    clientId: string;
  };
  cognitoIdentity: {
    userPoolId: string;
    jwksUrl: string;
  };
  sentry?: {
    environment: Environment;
    dsn: string;
    whitelistUrls: RegExp[];
  };
  freeShipping: {
    voucherCode: string;
  };
  contentfulConfig: {
    environment: string;
    spaceId: string;
    deliveryToken: string;
    previewToken: string;
  };
  wilisPageDefaultEntityId: string;
  notificationsSchedulerPeriod: number | null;
  enableTakeBack: boolean;
  loggingServiceURL: string;
  displayPhoneBotCodeInput: boolean;
  waitlistWhiteList: string[];
  enableWaitlistHistory: boolean;
  enableSubscriptionHistory: boolean;
  enableInfoPost: boolean;
  displayVariantsStock: boolean;
  enableEditErpInstallmentOrder: boolean;
  enableInfiniteScrolling: boolean;
  enablePriceDateInEditOrderForERP: boolean;
  userAllowedToUseReceptionSurvey: string[];
  slackChannelAlarmsWebhook?: string;
  enableCustomerPinFeature: boolean;
  enableOrderRecreation: boolean;
  enableSubscriptionProductManagement: boolean;
  enableInvoiceAddressEdit: boolean;
  enableInstallmentFactoringPayment: boolean;
  enableSetPreferredAddressOnAddressCreation: boolean;
  usersAllowedToExtendedSearch: string[];
  usersAllowedToEditProductNumber: string[];
  subscriptionProductManagerUsers: string[];
  hardcodedB2BCustomerId: string;
  chOrderEntryEnabled: boolean,
  learningStudioURL: string,
  enableLearningStudioLink: boolean,
  enableReklaOrders: boolean,
  preferredAddressEnabled: boolean,
  enableInstallmentsForAT: boolean,
}

const localConfiguration: Configuration = {
  environment: Environment.DEVELOPMENT,
  rootRoute: '/',
  backofficeUrl: 'http://localhost:3000',
  // orderServiceUrl: `http://${window.location.hostname}:8080/api`,
  orderServiceUrl: 'https://backoffice-service-proxy.checkout.hse24-development.cloud/os/api',
  customerServiceUrl: 'https://backoffice-service-proxy.checkout.hse24-development.cloud',
  callCenterServiceUrl: 'https://backoffice-service-proxy.checkout.hse24-development.cloud/ccs',
  factFinderApiUrl: {
    de: 'https://development.hse.de/dpl/ffa',
    at: 'https://development.hse.at/dpl/ffa',
    ch: 'https://development.hse24.ch/dpl/ffa',
  },
  productServiceUrl: {
    de: 'https://development.hse.de/dpl/pds',
    at: 'https://development.hse.at/dpl/pds',
    ch: 'https://development.hse24.ch/dpl/pds',
  },
  productServiceProxyUrl: 'https://backoffice-service-proxy.checkout.hse24-development.cloud/pds',
  stockHistoryServiceUrl: 'https://backoffice-service-proxy.checkout.hse24-development.cloud/shs',
  staticMediaUrl: 'https://pic.hse24-dach.net/media',
  addressCheckURL: 'https://development.hse.de/dpl/ac/',
  basketURL: 'https://backoffice-service-proxy.checkout.hse24-development.cloud/bs',
  paymentServiceURL: 'https://development.hse.de/dpl/pay/api',
  orderHistoryServiceURL: 'https://backoffice-service-proxy.checkout.hse24-development.cloud/ohs',
  // orderHistoryServiceURL: `http://${window.location.hostname}:8080/api`,
  searchUrl: {
    de: 'https://development.hse.de/dpl/search',
    at: 'https://development.hse.at/dpl/search',
    ch: 'https://development.hse24.ch/dpl/search',
  },
  upsells: 'https://backoffice-service-proxy.checkout.hse24-development.cloud/feed-service',
  //offers: 'https://dev-de.intern.hse24.de/ext-api/app/1/onAir',
  offers: {
    de: 'https://development.hse.de/dpl/tv-shows/productonair',
    at: 'https://development.hse.de/dpl/tv-shows/productonair',
    ch: 'https://development.hse24.ch/dpl/tv-shows/productonair',
  },
  virtualVoucherApiUrl: 'https://backoffice-service-proxy.checkout.hse24-development.cloud/cms/vouchers',
  osVoucherServiceApiUrl: 'https://backoffice-service-proxy.checkout.hse24-development.cloud/os/api',
  // virtualVoucherApiUrl: `http://${window.location.hostname}:8080/api`,
  cognito: {
    url: 'https://dpl-back-office-dev.auth.eu-central-1.amazoncognito.com',
    clientId: '1ubhub2ubvtmlbpdjjm07fb4s0',
  },
  cognitoIdentity: {
    userPoolId: 'eu-central-1_WlSIq3zn1',
    jwksUrl: 'https://cognito-idp.eu-central-1.amazonaws.com',
  },
  flags: {
    enableLanguageSwitch: true,
  },
  freeShipping: {
    voucherCode: '',
  },
  contentfulConfig: {
    environment: 'wilis-ticker-notifications',
    spaceId: 'gxqzdlfn45nk',
    deliveryToken: 'ebc7cdee0bed75029ab6b00dbc409a983ed4f6e6198a425089989897e4e3c65d',
    previewToken: 'a3a9af27b2d2e9dd437233799eada36b87fe30e6667e925e860897ad839dc4bc',
  },
  wilisPageDefaultEntityId: '4wZWvkxpo1B7mly43EvJX8',
  notificationsSchedulerPeriod: null,
  enableTakeBack: true,
  loggingServiceURL: 'https://development.hse.de/dpl/log/wilis',
  displayPhoneBotCodeInput: false,
  basketServiceAkamaiUrl: {
    de: 'https://development.hse.de/dpl/bs/basket',
    at: 'https://development.hse.at/dpl/bs/basket',
    ch: 'https://development.hse24.ch/dpl/bs/basket',
  },
  waitlistWhiteList: [],
  enableWaitlistHistory: true,
  enableSubscriptionHistory: true,
  enableInfoPost: true,
  displayVariantsStock: true,
  enableEditErpInstallmentOrder: true,
  enableInfiniteScrolling: true,
  enablePriceDateInEditOrderForERP: true,
  userAllowedToUseReceptionSurvey: [],
  enableCustomerPinFeature: true,
  enableOrderRecreation: true,
  enableSubscriptionProductManagement: true,
  enableInvoiceAddressEdit: true,
  enableInstallmentFactoringPayment: true,
  enableSetPreferredAddressOnAddressCreation: true,
  usersAllowedToExtendedSearch: [...wilisTeamAgentUsernames],
  subscriptionProductManagerUsers: wilisTeamAgentUsernames,
  usersAllowedToEditProductNumber: [],
  hardcodedB2BCustomerId: '26f6e7b2-1a3a-4b01-97b7-93d58a267ab5',
  chOrderEntryEnabled: true,
  learningStudioURL: 'https://ecampus.hse.com/login/index.php',
  enableLearningStudioLink: true,
  enableReklaOrders: true,
  preferredAddressEnabled: true,
  enableInstallmentsForAT: true,
};

const developmentConfiguration: Configuration = {
  environment: Environment.DEVELOPMENT,
  rootRoute: '/',
  backofficeUrl: 'https://wilis-view.checkout.hse24-development.cloud',
  // TODO: we need to check if we want to create a new proxy or not.
  orderServiceUrl: 'https://backoffice-service-proxy.checkout.hse24-development.cloud/os/api',
  callCenterServiceUrl: 'https://backoffice-service-proxy.checkout.hse24-development.cloud/ccs',
  customerServiceUrl: 'https://backoffice-service-proxy.checkout.hse24-development.cloud',
  factFinderApiUrl: {
    de: 'https://development.hse.de/dpl/ffa',
    at: 'https://development.hse.at/dpl/ffa',
    ch: 'https://development.hse24.ch/dpl/ffa',
  },
  productServiceUrl: {
    de: 'https://development.hse.de/dpl/pds',
    at: 'https://development.hse.at/dpl/pds',
    ch: 'https://development.hse24.ch/dpl/pds',
  },
  productServiceProxyUrl: 'https://backoffice-service-proxy.checkout.hse24-development.cloud/pds',
  stockHistoryServiceUrl: 'https://backoffice-service-proxy.checkout.hse24-development.cloud/shs',
  staticMediaUrl: 'https://pic.hse24-dach.net/media',
  addressCheckURL: 'https://development.hse.de/dpl/ac/',
  basketURL: 'https://backoffice-service-proxy.checkout.hse24-development.cloud/bs',
  orderHistoryServiceURL: 'https://backoffice-service-proxy.checkout.hse24-development.cloud/ohs',
  searchUrl: {
    de: 'https://development.hse.de/dpl/search',
    at: 'https://development.hse.at/dpl/search',
    ch: 'https://development.hse24.ch/dpl/search',
  },
  upsells: 'https://backoffice-service-proxy.checkout.hse24-development.cloud/feed-service',
  paymentServiceURL: 'https://development.hse.de/dpl/pay/api',
  //offers: 'https://dev-de.intern.hse24.de/ext-api/app/1/onAir',
  offers: {
    de: 'https://development.hse.de/dpl/tv-shows/productonair',
    at: 'https://development.hse.de/dpl/tv-shows/productonair',
    ch: 'https://development.hse24.ch/dpl/tv-shows/productonair'
  },
  virtualVoucherApiUrl: 'https://backoffice-service-proxy.checkout.hse24-development.cloud/cms/vouchers',
  osVoucherServiceApiUrl: 'https://backoffice-service-proxy.checkout.hse24-development.cloud/os/api',
  cognito: {
    url: 'https://dpl-back-office-dev.auth.eu-central-1.amazoncognito.com',
    clientId: '1ubhub2ubvtmlbpdjjm07fb4s0',
  },
  cognitoIdentity: {
    userPoolId: 'eu-central-1_WlSIq3zn1',
    jwksUrl: 'https://cognito-idp.eu-central-1.amazonaws.com',
  },
  flags: {
    enableLanguageSwitch: true,
  },
  sentry: {
    environment: Environment.DEVELOPMENT,
    dsn: 'https://81986c73b33f43d7b7f367d1746212a8@o303126.ingest.sentry.io/5281305',
    whitelistUrls: [
      /https?:\/\/wilis-view\.checkout\.hse24-development\.cloud/,
    ],
  },
  freeShipping: {
    voucherCode: '',
  },
  contentfulConfig: {
    environment: 'wilis-ticker-notifications',
    spaceId: 'gxqzdlfn45nk',
    deliveryToken: 'ebc7cdee0bed75029ab6b00dbc409a983ed4f6e6198a425089989897e4e3c65d',
    previewToken: 'a3a9af27b2d2e9dd437233799eada36b87fe30e6667e925e860897ad839dc4bc',
  },
  wilisPageDefaultEntityId: '4wZWvkxpo1B7mly43EvJX8',
  notificationsSchedulerPeriod: null,
  enableTakeBack: true,
  loggingServiceURL: 'https://development.hse.de/dpl/log/wilis',
  displayPhoneBotCodeInput: false,
  basketServiceAkamaiUrl: {
    de: 'https://development.hse.de/dpl/bs/basket',
    at: 'https://development.hse.at/dpl/bs/basket',
    ch: 'https://development.hse24.ch/dpl/bs/basket',
  },
  waitlistWhiteList: [],
  enableWaitlistHistory: true,
  enableSubscriptionHistory: true,
  enableInfoPost: true,
  displayVariantsStock: true,
  enableEditErpInstallmentOrder: true,
  enableInfiniteScrolling: true,
  enablePriceDateInEditOrderForERP: true,
  userAllowedToUseReceptionSurvey: [],
  enableCustomerPinFeature: true,
  enableOrderRecreation: true,
  enableSubscriptionProductManagement: true,
  enableInvoiceAddressEdit: true,
  enableInstallmentFactoringPayment: true,
  enableSetPreferredAddressOnAddressCreation: true,
  usersAllowedToExtendedSearch: [...wilisTeamAgentUsernames],
  subscriptionProductManagerUsers: wilisTeamAgentUsernames,
  usersAllowedToEditProductNumber: [],
  hardcodedB2BCustomerId: '26f6e7b2-1a3a-4b01-97b7-93d58a267ab5',
  chOrderEntryEnabled: true,
  learningStudioURL: 'https://ecampus.hse.com/login/index.php',
  enableLearningStudioLink: true,
  enableReklaOrders: true,
  preferredAddressEnabled: true,
  enableInstallmentsForAT: true,
};

const stagingConfiguration: Configuration = {
  environment: Environment.STAGING,
  rootRoute: '/',
  backofficeUrl: 'https://wilis-view.checkout.hse24-staging.cloud',
  orderServiceUrl: 'https://backoffice-service-proxy.checkout.hse24-staging.cloud/os/api',
  callCenterServiceUrl: 'https://backoffice-service-proxy.checkout.hse24-staging.cloud/ccs',
  customerServiceUrl: 'https://backoffice-service-proxy.checkout.hse24-staging.cloud',
  factFinderApiUrl: {
    de: 'https://qas.hse.de/dpl/ffa',
    at: 'https://qas.hse.at/dpl/ffa',
    ch: 'https://qas.hse24.ch/dpl/ffa',
  },
  productServiceUrl: {
    de: 'https://qas.hse.de/dpl/pds',
    at: 'https://qas.hse.at/dpl/pds',
    ch: 'https://qas.hse24.ch/dpl/pds',
  },
  productServiceProxyUrl: 'https://backoffice-service-proxy.checkout.hse24-staging.cloud/pds',
  stockHistoryServiceUrl: 'https://backoffice-service-proxy.checkout.hse24-staging.cloud/shs',
  staticMediaUrl: 'https://pic.hse24-dach.net/media',
  addressCheckURL: 'https://qas.hse.de/dpl/ac/',
  basketURL: 'https://backoffice-service-proxy.checkout.hse24-staging.cloud/bs',
  paymentServiceURL: 'https://qas.hse.de/dpl/pay/api',
  orderHistoryServiceURL: 'https://backoffice-service-proxy.checkout.hse24-staging.cloud/ohs',
  searchUrl: {
    de: 'https://qas.hse.de/dpl/search',
    at: 'https://qas.hse.at/dpl/search',
    ch: 'https://qas.hse24.ch/dpl/search',
  },
  upsells: 'https://backoffice-service-proxy.checkout.hse24-staging.cloud/feed-service',
  offers: {
    de: 'https://qas.hse.de/dpl/tv-shows/productonair',
    at: 'https://qas.hse.de/dpl/tv-shows/productonair',
    ch: 'https://qas.hse24.ch/dpl/tv-shows/productonair'
  },
  virtualVoucherApiUrl: 'https://backoffice-service-proxy.checkout.hse24-staging.cloud/cms/vouchers',
  osVoucherServiceApiUrl: 'https://backoffice-service-proxy.checkout.hse24-staging.cloud/os/api',
  cognito: {
    url: 'https://dpl-back-office-staging.auth.eu-central-1.amazoncognito.com',
    clientId: '7jg2sp3ouqhlk34gei98g5gaeh',
  },
  cognitoIdentity: {
    userPoolId: 'eu-central-1_33hHtkQpD',
    jwksUrl: 'https://cognito-idp.eu-central-1.amazonaws.com',
  },
  flags: {
    enableLanguageSwitch: false,
  },
  sentry: {
    environment: Environment.STAGING,
    dsn: 'https://81986c73b33f43d7b7f367d1746212a8@o303126.ingest.sentry.io/5281305',
    whitelistUrls: [
      /https?:\/\/wilis-view\.checkout\.hse24-staging\.cloud/,
    ],
  },
  freeShipping: {
    voucherCode: 'SERVICE',
  },
  contentfulConfig: {
    environment: 'master',
    spaceId: 'gxqzdlfn45nk',
    deliveryToken: 'ebc7cdee0bed75029ab6b00dbc409a983ed4f6e6198a425089989897e4e3c65d',
    previewToken: 'a3a9af27b2d2e9dd437233799eada36b87fe30e6667e925e860897ad839dc4bc',
  },
  wilisPageDefaultEntityId: '29g1mHIIlFS39lJjm1Jdwb',
  notificationsSchedulerPeriod: null,
  enableTakeBack: true,
  loggingServiceURL: 'https://qas.hse.de/dpl/log/wilis',
  displayPhoneBotCodeInput: false,
  basketServiceAkamaiUrl: {
    de: 'https://qas.hse.de/dpl/bs/basket',
    at: 'https://qas.hse.at/dpl/bs/basket',
    ch: 'https://qas.hse24.ch/dpl/bs/basket',
  },
  waitlistWhiteList: [],
  enableWaitlistHistory: true,
  enableSubscriptionHistory: true,
  enableInfoPost: true,
  displayVariantsStock: false,
  enableEditErpInstallmentOrder: true,
  enableInfiniteScrolling: true,
  enablePriceDateInEditOrderForERP: true,
  userAllowedToUseReceptionSurvey: ['NWERN', 'DENWER', 'DETSZEN', 'elias.mel', 'V1'],
  enableCustomerPinFeature: true,
  enableOrderRecreation: true,
  enableSubscriptionProductManagement: true,
  enableInvoiceAddressEdit: true,
  enableInstallmentFactoringPayment: true,
  enableSetPreferredAddressOnAddressCreation: true,
  usersAllowedToExtendedSearch: [...wilisTeamAgentUsernames],
  subscriptionProductManagerUsers: wilisTeamAgentUsernames,
  usersAllowedToEditProductNumber: ['DESPRASC','DENWERNE','REDOUANI'],
  hardcodedB2BCustomerId: '26f6e7b2-1a3a-4b01-97b7-93d58a267ab5',
  chOrderEntryEnabled: true,
  learningStudioURL: 'https://ecampus.hse.com/login/index.php',
  enableLearningStudioLink: true,
  enableReklaOrders: true,
  preferredAddressEnabled: true,
  enableInstallmentsForAT: true,
};

const productionConfiguration: Configuration = {
  environment: Environment.PRODUCTION,
  rootRoute: '/',
  backofficeUrl: 'https://wilis-view.checkout.hse24.cloud',
  orderServiceUrl: 'https://backoffice-service-proxy.checkout.hse24.cloud/os/api',
  callCenterServiceUrl: 'https://backoffice-service-proxy.checkout.hse24.cloud/ccs',
  customerServiceUrl: 'https://backoffice-service-proxy.checkout.hse24.cloud',
  factFinderApiUrl: {
    de: 'https://www.hse.de/dpl/ffa',
    at: 'https://www.hse.at/dpl/ffa',
    ch: 'https://www.hse24.ch/dpl/ffa',
  },
  productServiceUrl: {
    de: 'https://www.hse.de/dpl/pds',
    at: 'https://www.hse.at/dpl/pds',
    ch: 'https://www.hse24.ch/dpl/pds',
  },
  productServiceProxyUrl: 'https://backoffice-service-proxy.checkout.hse24.cloud/pds',
  stockHistoryServiceUrl: 'https://backoffice-service-proxy.checkout.hse24.cloud/shs',
  staticMediaUrl: 'https://pic.hse24-dach.net/media',
  addressCheckURL: 'https://www.hse.de/dpl/ac/',
  basketURL: 'https://backoffice-service-proxy.checkout.hse24.cloud/bs',
  paymentServiceURL: 'https://www.hse.de/dpl/pay/api',
  orderHistoryServiceURL: 'https://backoffice-service-proxy.checkout.hse24.cloud/ohs',
  searchUrl: {
    de: 'https://www.hse.de/dpl/search',
    at: 'https://www.hse.at/dpl/search',
    ch: 'https://www.hse24.ch/dpl/search',
  },
  upsells: 'https://backoffice-service-proxy.checkout.hse24.cloud/feed-service',
  offers: {
    de: 'https://www.hse.de/dpl/tv-shows/productonair',
    at: 'https://www.hse.de/dpl/tv-shows/productonair',
    ch: 'https://www.hse24.ch/dpl/tv-shows/productonair',
  },
  virtualVoucherApiUrl: 'https://backoffice-service-proxy.checkout.hse24.cloud/cms/vouchers',
  osVoucherServiceApiUrl: 'https://backoffice-service-proxy.checkout.hse24.cloud/os/api',
  cognito: {
    url: 'https://dpl-back-office-production.auth.eu-central-1.amazoncognito.com',
    clientId: '3um14k4dejcsngvsenjccv4jdp',
  },
  cognitoIdentity: {
    userPoolId: 'eu-central-1_sGuevZyG9',
    jwksUrl: 'https://cognito-idp.eu-central-1.amazonaws.com',
  },
  flags: {
    enableLanguageSwitch: false,
  },
  sentry: {
    environment: Environment.PRODUCTION,
    dsn: 'https://81986c73b33f43d7b7f367d1746212a8@o303126.ingest.sentry.io/5281305',
    whitelistUrls: [
      /https?:\/\/wilis-view\.checkout\.hse24\.cloud/,
    ],
  },
  freeShipping: {
    voucherCode: '§22$23!6!',
  },
  contentfulConfig: {
    environment: 'master',
    spaceId: 'gxqzdlfn45nk',
    deliveryToken: 'ebc7cdee0bed75029ab6b00dbc409a983ed4f6e6198a425089989897e4e3c65d',
    previewToken: 'a3a9af27b2d2e9dd437233799eada36b87fe30e6667e925e860897ad839dc4bc',
  },
  wilisPageDefaultEntityId: '35SsmFbyzyhKvoGaJZHMXO',
  notificationsSchedulerPeriod: 60000,
  enableTakeBack: true,
  loggingServiceURL: 'https://www.hse.de/dpl/log/wilis',
  displayPhoneBotCodeInput: false,
  basketServiceAkamaiUrl: {
    de: 'https://www.hse.de/dpl/bs/basket',
    at: 'https://www.hse.at/dpl/bs/basket',
    ch: 'https://www.hse24.ch/dpl/bs/basket',
  },
  waitlistWhiteList: [],
  enableWaitlistHistory: true,
  enableSubscriptionHistory: true,
  enableInfoPost: true,
  displayVariantsStock: false,
  enableEditErpInstallmentOrder: true,
  enableInfiniteScrolling: true,
  enablePriceDateInEditOrderForERP: true,
  userAllowedToUseReceptionSurvey: ['elias.mel', 'V1'],
  enableCustomerPinFeature: true,
  enableOrderRecreation: true,
  enableSubscriptionProductManagement: true,
  enableInvoiceAddressEdit: true,
  enableInstallmentFactoringPayment: true,
  enableSetPreferredAddressOnAddressCreation: false,
  usersAllowedToExtendedSearch: [],
  subscriptionProductManagerUsers: [...wilisTeamAgentUsernames, 'DESPRASC', 'SANDRA', 'DETSCHWA', 'D-BBRE', 'DEAGLUFK'],
  usersAllowedToEditProductNumber: ['DESPRASC','DENWERNE','REDOUANI'],
  hardcodedB2BCustomerId: '4bf59ce2-7990-4efb-972d-041b7e272c8d',
  chOrderEntryEnabled: true,
  learningStudioURL: 'https://ecampus.hse.com/login/index.php',
  enableLearningStudioLink: true,
  enableReklaOrders: true,
  preferredAddressEnabled: true,
  enableInstallmentsForAT: true,
};

const environmentConfiguration = (environment: string): Configuration => {
  switch (environment) {
  case 'development':
    return developmentConfiguration;
  case 'staging':
    return stagingConfiguration;
  case 'production':
    return productionConfiguration;
  default:
    return localConfiguration;
  }
};

function mergeWithEnvVars(configuration: Configuration): Configuration {
  try {
    return { ...configuration, ...(window.customVars as EnvVars) };
  } catch (err) {
    console.error('could not load EnvVars', err);
    return configuration;
  }
}

const Config = {
  env: mergeWithEnvVars(environmentConfiguration(window.hseEnv || process.env.REACT_APP_ENVIRONMENT || 'local')),
};

export default Config;
