import {
  call,
  put,
  select,
} from 'redux-saga/effects';

import { OrderState } from 'src/constants/types';
import { logErrorEvent } from 'src/logging/loggingActions';
import { appSelectors } from 'src/redux/app/selectors/appSelector';
import { customerInfoSelectors } from 'src/redux/customer/selectors/customerInfoSelectors';
import { trackOrderEvent } from 'src/redux/order/orderEntrySlice';
import { VoucherState } from 'src/redux/order/voucherSlice';
import { setSource } from 'src/redux/product/productDetailsSlice';
import { doFetchProducts } from 'src/sagas/product/productService';
import { Customer } from 'src/types/customer/customer';
import { CreateOrderResultResponse } from 'src/types/offer/CreateOrderResultResponse';
import { OfferItem } from 'src/types/offer/Offer';
import { OrderItem } from 'src/types/offer/OrderItem';
import { ProductDetails } from 'src/types/product/product';
import { encryptCDT } from 'src/utils/encryptCDT';
import toBaseProductNumber from 'src/utils/formatters/toBaseProductNumber';


export function *parseOrderForTracking(
  salesChannel: string,
  voucher: VoucherState,
  orderItems: OrderItem[],
  orderTrack?: CreateOrderResultResponse,
  customer?: Customer,
  agentUsername?: string,
) {
  if (agentUsername) {
    if (orderTrack && agentUsername && customer) {
      const cdt = encryptCDT(customer.firstName, customer.lastName, customer.dateOfBirth ?? '');
      const items = yield call(enrichOrderTrackItemsWithCrossSellMainProduct, orderTrack.items, salesChannel);
      return { orderTrack: { ...orderTrack, items }, orderItems, agentUsername, voucher, salesChannel, cdt };
    }
  }
}

function *enrichOrderTrackItemsWithCrossSellMainProduct(items: OfferItem[], salesChannel: string) {
  const crossSellMainProductNos = items.map(i => i.crossSell?.leadProductSku).filter(i => !!i) as string[];
  if (crossSellMainProductNos.length > 0) {
    try {
      const crossSellMainProducts: ProductDetails[] = yield call(doFetchProducts, crossSellMainProductNos, salesChannel);
      return items.map(i => {
        if (!i.crossSell) {
          return i;
        }
        const mainProduct = crossSellMainProducts.find(
          p => i.crossSell?.leadProductSku && p.baseProductNo === toBaseProductNumber(i.crossSell?.leadProductSku)
        );
        return {
          ...i,
          crossSell: {
            ...i.crossSell,
            leadProductSku: mainProduct?.baseProductNo ?? i.crossSell?.leadProductSku,
            leadProductName: mainProduct?.name?.long ?? mainProduct?.name?.short,
          }
        };
      });
    } catch (err) {
      yield put(logErrorEvent({ message: 'could not fetch cross_sell main products', err }));
    }
  }
  return items;
}

function* trackOrder() {
  try {
    const { salesChannel, voucher, newOrder, orderEntry }: OrderState = yield select(state => state.order);
    const customer = yield select(customerInfoSelectors.getCustomer);
    const agentUsername = yield select(appSelectors.getAgentUsername);

    if (agentUsername) {
      const trackingPayload = yield call(
        parseOrderForTracking,
        salesChannel.value,
        voucher,
        orderEntry.orderLineItems,
        newOrder.orderResponse,
        customer,
        agentUsername,
      );

      if (trackingPayload) {
        yield put(trackOrderEvent(trackingPayload));
        yield put(setSource(undefined));
      }
    }
  } catch (e) {
    yield put(logErrorEvent({ message: 'Could not track order', err: e }));
  }
}

export default trackOrder;
