import authenticateCustomerWatcher from 'src/sagas/customerPinVerification/authenticateCustomerSaga';
import requestNewCustomerPinWatcher from 'src/sagas/customerPinVerification/requestNewCustomerPin';
import skipCustomerPinWatcher from 'src/sagas/customerPinVerification/skipCustomerPin';

import checkBirthdateWatcher from './checkBirthdateSaga';
import checkCustomerPinValidityWatcher from './checkCustomerPin';


export default [
  checkCustomerPinValidityWatcher,
  skipCustomerPinWatcher,
  requestNewCustomerPinWatcher,
  authenticateCustomerWatcher,
  checkBirthdateWatcher,
];
