export default {
  'payment.bankInfo': 'Bitte Bankverbindung eingeben',
  // Validation
  'payment.iban.required': 'please check IBAN field',
  'payment.iban.notValid': 'IBAN not valid',
  'payment.iban.noRecognized': 'IBAN not recognized',
  'payment.bankName.required': 'please check Bank Name',
  'payment.accountHolder.required': 'please check Account Holder',
  'payment.iban': 'IBAN',
  'payment.bankName': 'Kreditinstitut (wird berechnet)',
  'payment.accountHolder': 'Account Holder',
  'payment.submit': 'Hinzufügen',
  'INVOICE': 'Invoice',
  'DIRECT_DEBIT': 'Direct Debit',
  'INSTALLMENTS': 'Installments',
  'INSTALLMENTS_FACTORING': 'Ratenkauf',
  'RATENKAUF': 'Ratenkauf',
  'CASH_ON_DELIVERY': 'Cash on Delivery',
  'CREDIT_CARD': 'Kreditkarte',
  'payment.accountHolder.minCharct': 'The account holder must consist of more than 1 character.',
  'paymentSelection.installments.chooseInstallmentsPlan':
    'Bitte Ratenplan auswählen',
  'paymentSelection.installments.chooseInstallmentsPlan.subtitle':
    'In welchen Raten möchten Sie zahlen? Falls Sie einen Gutschein einlösen wollen, wählen Sie bitte eine andere Zahlungsart.',

  'paymentSelection.installments.installmentPlan.modalTitle':
    '{installmentCount} Monatsraten je ca. {monthlyRate}',
  'paymentSelection.installments.installmentPlan.installments':
    '{installmentRange} Monatsrate zu',
  'paymentSelection.installments.installmentPlan.continue':
    'Weiter mit {installmentCount} Monatsraten',
  'paymentSelection.installments.installmentPlan.finalInstallment':
    '{finalInstallmentNumber} Monatsrate',
  'paymentSelection.installments.installmentPlan.total': 'Gesamtbetrag',
  'paymentSelection.installments.installmentPlan.interestRate':
    'Effektiver Jahreszins {interestRate}%',
  'paymentSelection.installments.installmentPlan.totalInterest': 'Zinsen',
  'paymentSelection.installments.dueDay': 'Due Day',
  'paymentSelection.installments.dueDay.title': 'Please select due day',
  'paymentSelection.installments.dueDay.subtitle':
    'When do you want to pay the installment?',
  'paymentSelection.installments.dueDay.first': '1st of the Month',
  'paymentSelection.installments.dueDay.fifteenth': '15th of the Month',
  'paymentSelection.continue': 'Continue',
  'paymentSelection.installments.bankAccount': 'Please insert your banking details',
  'paymentSelection.installments.bankName': 'Credit institution (will be charged)',
  'prefilled.change': 'change',
  'payment.chooseAsPreferred': 'Set as preferred payment method',
  'payment.preferred': 'Preferred method of payment',
  'paymentSelection.installments.chooseInstallmentsFactoringPlan.subtitle': 'In which installments would you like to pay?',
  'paymentSelection.installments.installmentFactoryPlan.interestRate': 'Effective interest rate {interestRate}%',
  'paymentSelection.installments.installmentFactoryPlan.nominalInterestRate': 'Nominal rate {nominalInterestRate}%',
  'paymentSelection.installments.installmentFactoryPlan.fundingAmount': 'Funding amount',
  'paymentSelection.installments.installmentFactoryPlan.installments': '{installmentRange} Monthly rate ',
  'paymentSelection.installments.installmentFactoryPlan.installmentsDates': '({startDate} - {endDate})',
  'paymentSelection.installments.installmentFactoryPlan.finalInstallment': '{finalInstallmentNumber} Monthly rate ',
  'paymentSelection.installments.installmentFactoryPlan.finalInstallmentDate': '({lastDate})',
  'paymentSelection.installments.chooseInstallmentFactoryPlan.subtitle': 'In which installments would you like to pay?',
  'paymentSelection.installments.campagneType.label': 'Choose the type of campaign',
  'paymentSelection.installments.zeroPercent.label': '0% Financement',
  'paymentSelection.installments.installmentFactoryPlan.conditions.headline': 'Unbedingt vorlesen',
  'paymentSelection.installments.installmentFactoryPlan.conditions.body.firstTime': `
    Beim Ratenkauf arbeiten wir mit der payolution GmbH und der Bank Frick & Co. AG zusammen.
    Die Ratenkaufbedingungen und Datenschutzbedingungen finden Sie in unserem Online-Shop unter ‚Ratenkauf‘.
  `,
  'paymentSelection.installments.installmentFactoryPlan.conditions.body.alreadyOrderedWith': `Alle Bedingungen und Datenschutzhinweise finden Sie in unserem Online-Shop unter ‚Ratenkauf‘.
  `
};
