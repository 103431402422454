import { v4 as uuidv4 } from 'uuid';

import { DeliveryAddress } from 'src/types/customer/address';
import { Customer, MarketResearchConsent } from 'src/types/customer/customer';
import { ReceptionProvider } from 'src/types/customer/ReceptionSurveyQuestionsData';
import { Payment } from 'src/types/offer/Payment';


export const LAST_NEW_CUSTOMERS = 'lastNewCustomers';

export const MAX = 10;

export interface SavedCustomerConsents {
  phoneMarketResearch?: MarketResearchConsent;
  emailMarketResearch?: MarketResearchConsent;
  phoneNumber?: string;
  email?: string;
  submitted?: boolean;
}
export type SavedCustomer = {
  id: string,
  customer: Customer,
  deliveryAddress?: DeliveryAddress,
  payment?: Payment,
  wasAskedAboutConsents?: boolean,
  loginTime: string,
  reception?: ReceptionProvider,
  guestCustomerConsentsStepIsSkipped?: boolean;
  consents?: SavedCustomerConsents;
}

const buildSavedCustomer = (customer: Customer) => ({
  id: uuidv4(),
  customer: customer,
  loginTime: new Date().toISOString(),
});

function saveCustomer(customer: Customer, localId?: string): string {
  const customers = getLastNewCustomers();
  const extId = customer.externalCustomerId;
  const exists = !!extId && customers.find(({ customer: { externalCustomerId } }) => externalCustomerId === extId);
  const existedSavedItem = exists ? exists : !!localId && customers.find(e => e.id === localId);

  if (existedSavedItem) {
    updateCustomerLoginTime(existedSavedItem.id);
    return existedSavedItem.id;
  }

  const savedCustomer = buildSavedCustomer(customer);
  customers.unshift(savedCustomer);

  if(customers.length > MAX) {
    customers.pop();
  }
  sessionStorage.setItem(LAST_NEW_CUSTOMERS, JSON.stringify(customers));
  return savedCustomer.id;
}

function upsertCustomer(customer: Customer, localId: string) {
  const storedCustomers = getLastNewCustomers();
  const storedCustomer = !!localId && storedCustomers?.find(e => e.id === localId);
  if(!storedCustomer) {
    return;
  }

  const updatedCustomer: SavedCustomer = {
    ...storedCustomer,
    customer: {
      ...storedCustomer.customer,
      ...customer,
    },
  };
  const otherCustomers = storedCustomers.filter(e => e.id !== localId);
  otherCustomers.unshift(updatedCustomer);
  sessionStorage.setItem(LAST_NEW_CUSTOMERS, JSON.stringify(otherCustomers));
}

function updateCustomerLoginTime(id: string): void {
  const customers = getLastNewCustomers();
  const found = customers?.find(customer => customer.id === id);

  if(customers && found) {
    found.loginTime = new Date().toISOString();
    customers.sort((c1, c2) => new Date(c2.loginTime).getTime() - new Date(c1.loginTime).getTime());
    sessionStorage.setItem(LAST_NEW_CUSTOMERS, JSON.stringify(customers));
  }
}

function setReceptionByLocalCustomerId(id: string, reception?: ReceptionProvider): void {
  const customers = getLastNewCustomers();
  const found = customers?.find(customer => customer.id === id);

  if(customers && found) {
    found.loginTime = new Date().toISOString();
    found.reception = reception;
    customers.sort((c1, c2) => new Date(c2.loginTime).getTime() - new Date(c1.loginTime).getTime());
    sessionStorage.setItem(LAST_NEW_CUSTOMERS, JSON.stringify(customers));
  }
}

function getLastCustomerByExternalId(externalCustomerId: string): SavedCustomer | undefined {
  if(!externalCustomerId) {
    return undefined;
  }
  const customers = getLastNewCustomers();
  return customers.find(customer => customer.customer.externalCustomerId === externalCustomerId);
}

function getLastNewCustomers(): SavedCustomer[] {
  try {
    const customers = sessionStorage.getItem(LAST_NEW_CUSTOMERS);
    return customers ? JSON.parse(customers) as SavedCustomer[] : [];
  } catch (err) {
    return [];
  }
}

function findLastNewCustomer(id?: string): SavedCustomer | undefined {
  if(!id) {
    return undefined;
  }
  const customers = getLastNewCustomers();
  return  customers.find(customer => customer.id === id);
}

function updateLastNewDeliveryAddress(id: string, deliveryAddress: DeliveryAddress) {
  if(!id) {
    return;
  }
  setCustomerInfo(s => s.id === id, { deliveryAddress });
}

function removeLastNewDeliveryAddress(id?: string) {
  if(!id) {
    return;
  }
  const storedCustomer = findLastNewCustomer(id);
  if (storedCustomer && !(storedCustomer.deliveryAddress?.usedInOrder)) {
    delete storedCustomer.deliveryAddress;
    const storedCustomers = getLastNewCustomers();
    const otherCustomers = storedCustomers.filter(e => e.id !== storedCustomer.id);
    otherCustomers.unshift(storedCustomer);
    sessionStorage.setItem(LAST_NEW_CUSTOMERS, JSON.stringify(otherCustomers));
  }
}

function updateLastNewCustomerPayment(id: string, paymentDetails: Payment | undefined) {
  if(!id) {
    return undefined;
  }
  setCustomerInfo(s => s.id === id, { paymentDetails });
}

function setCustomerWasAskedAboutConsents(id?: string, skipped?: boolean) {
  if(!id) {
    return undefined;
  }
  setCustomerInfo(s => s.id === id, { wasAskedAboutConsents: true, guestCustomerConsentsStepIsSkipped: skipped });
}

function setCustomerBirthDate(externalCustomerId: string, dateOfBirth?: string) {
  if(!externalCustomerId) {
    return undefined;
  }
  setCustomerInfo(s => s.customer.externalCustomerId === externalCustomerId, { dateOfBirth });
}

function setCustomerEmailPhoneNumber(id?: string, phoneNumber?: string, email?: string) {
  if(!id) {
    return undefined;
  }
  setCustomerInfo(s => s.id === id, { phoneNumber, email });
}

function setCustomerConsents(id?: string, consents?: SavedCustomerConsents) {
  if(!id) {
    return undefined;
  }
  setCustomerInfo(s => s.id === id, { consents });
}

interface PatchCustomer {
  paymentDetails?: Payment;
  deliveryAddress?: DeliveryAddress;
  wasAskedAboutConsents?: boolean;
  guestCustomerConsentsStepIsSkipped?: boolean;
  dateOfBirth?: string;
  phoneNumber?: string;
  email?: string;
  consents?: SavedCustomerConsents;
}

function setCustomerInfo(searchFun: (s: SavedCustomer) => boolean, data: PatchCustomer) {
  const storedCustomers = getLastNewCustomers();
  const storedCustomer = storedCustomers.find(searchFun);
  if(storedCustomer) {
    storedCustomer.payment = data.paymentDetails ?? storedCustomer.payment;
    storedCustomer.deliveryAddress = data.deliveryAddress ?? storedCustomer.deliveryAddress;
    storedCustomer.wasAskedAboutConsents = data.wasAskedAboutConsents ?? storedCustomer.wasAskedAboutConsents;
    storedCustomer.guestCustomerConsentsStepIsSkipped = data.guestCustomerConsentsStepIsSkipped ?? storedCustomer.guestCustomerConsentsStepIsSkipped;
    storedCustomer.customer.phoneNumber = data.phoneNumber ?? storedCustomer.customer.phoneNumber;
    storedCustomer.customer.email = data.email ?? storedCustomer.customer.email;
    if(data.dateOfBirth) {
      storedCustomer.customer.dateOfBirth = data.dateOfBirth;
    }
    storedCustomer.consents = data.consents ?? storedCustomer.consents;

    const otherCustomers = storedCustomers.filter(e => e.id !== storedCustomer.id);
    otherCustomers.unshift(storedCustomer);
    sessionStorage.setItem(LAST_NEW_CUSTOMERS, JSON.stringify(otherCustomers));
  }
}

export default {
  LAST_NEW_CUSTOMERS,
  saveCustomer,
  upsertCustomer,
  updateLastNewCustomerPayment,
  updateLastNewDeliveryAddress,
  removeLastNewDeliveryAddress,
  findLastNewCustomer,
  getLastNewCustomers,
  getLastCustomerByExternalId,
  setCustomerWasAskedAboutConsents,
  setCustomerEmailPhoneNumber,
  updateCustomerLoginTime,
  setReceptionByLocalCustomerId,
  setCustomerBirthDate,
  setCustomerConsents,
};
