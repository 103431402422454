import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { xor } from 'lodash';

import { VoucherFailureResponse } from 'src/types/offer/ErrorResponse';
import { NonRedeemableVoucher } from 'src/types/voucher/NonRedeemableVoucher';
import { RedeemedVouchersResponse } from 'src/types/voucher/RedeemedVoucherResponse';
import { VoucherSuggestionsResponse } from 'src/types/voucher/SuggestedVoucherResponse';
import { VoucherRestriction } from 'src/types/voucher/VirtualVoucherRequest';
import { VirtualVoucherType } from 'src/types/voucher/VirtualVoucherType';
import {
  PercentageVoucher,
  ProgressiveDiscountInfo,
  VoucherErrorResponse,
  VoucherResponse,
} from 'src/types/voucher/VoucherResponse';


export type VoucherState = {
    code: string;
    type?: VirtualVoucherType;
    value?: number;
    calculatedPercentageValue?: number;
    percentage?: number;
    error?: VoucherErrorResponse;
    loading: boolean;
    restrictions: VoucherRestriction[];
    suggestions?: VoucherSuggestionsResponse;
    pendingRedeemVouchers: string[];
    redeemableVouchers: string[];
    nonRedeemableVouchers: NonRedeemableVoucher[],
    redeemedVouchers?: RedeemedVouchersResponse;
    failedVoucherCode?: string;
    viewFailedVoucher?: boolean;
    showDeletedVoucherHint: boolean;
    progressiveDiscountInfo?: ProgressiveDiscountInfo;
    invalidVouchers: VoucherFailureResponse[];
};

export const voucherInitialState: VoucherState = {
  code: '',
  loading: false,
  pendingRedeemVouchers:[],
  redeemableVouchers: [],
  nonRedeemableVouchers: [],
  failedVoucherCode: undefined,
  viewFailedVoucher: false,
  restrictions: [],
  error: undefined,
  showDeletedVoucherHint: false,
  invalidVouchers: [],
};

const voucherSlice = createSlice({
  name: 'voucher',
  initialState: voucherInitialState,
  reducers: {
    handleVoucherSession(state) {  return state; },
    submitVoucher(state) {
      state.loading = true;
      state.error = undefined;
      state.showDeletedVoucherHint=false;
    },
    submitVoucherSuccess(state,  { payload }: PayloadAction<VoucherResponse>) {
      const percentageVoucher = payload.type === VirtualVoucherType.percentage ? payload as PercentageVoucher: undefined;
      state.type = payload.type;
      state.value = payload.value;
      state.calculatedPercentageValue = percentageVoucher?.calculatedPercentageValue;
      state.percentage = percentageVoucher?.percentage;
      state.error = undefined;
      state.loading = false;
      state.restrictions = payload.restrictions ?? [];
      state.progressiveDiscountInfo = 'progressiveDiscountInfo' in payload  ? payload.progressiveDiscountInfo : undefined;
    },
    submitVoucherFailed(state, { payload }: PayloadAction<VoucherErrorResponse | undefined>) {
      state.type = undefined;
      state.value = undefined;
      state.percentage = undefined;
      state.error = payload;
      state.failedVoucherCode = payload?.voucherCode;
      state.loading = false;
      state.restrictions = [];
      state.invalidVouchers = payload?.invalidVouchers ?? [];
    },
    setViewFailedVoucher(state) {
      state.viewFailedVoucher = true;
    },
    resetVoucher() {
      return voucherInitialState;
    },
    resetVoucherState() {
      return voucherInitialState;
    },
    changeVoucher(state, { payload }: PayloadAction<string>) {
      state.failedVoucherCode = undefined;
      state.code = payload;
      state.error = undefined;
      state.progressiveDiscountInfo = undefined;
    },
    getVouchersSuggestions(state) {
      state.loading = true;
    },
    getVouchersSuggestionsSuccess(state, { payload }: PayloadAction<VoucherSuggestionsResponse>) {
      state.loading = false;
      state.suggestions = payload;
    },
    getRedeemedVouchers(state) {
      state.loading = true;
    },
    getRedeemedVouchersSuccess(state, { payload }: PayloadAction<RedeemedVouchersResponse>) {
      state.loading = false;
      state.redeemedVouchers = payload;
    },
    clearRedeemedVouchers(state) {
      state.redeemedVouchers = undefined;
    },
    submitSuggestedVoucherDone(state) {
      state.loading = false;
    },
    verifyVoucher(state, { payload }: PayloadAction<string>) {
      state.pendingRedeemVouchers = xor(state.pendingRedeemVouchers, [payload]);
    },
    verifyVoucherSuccess(state, { payload }: PayloadAction<string>) {
      state.redeemableVouchers = [...state.redeemableVouchers, payload];
      state.pendingRedeemVouchers = state.pendingRedeemVouchers.filter(voucher => voucher !== payload);
    },
    verifyVoucherFailure(state, { payload }: PayloadAction<string>) {
      state.pendingRedeemVouchers = state.pendingRedeemVouchers.filter(voucher => voucher !== payload);
    },
    addNonRedeemableVoucher(state, { payload }: PayloadAction<NonRedeemableVoucher>) {
      state.nonRedeemableVouchers = [...state.nonRedeemableVouchers, payload];
      state.pendingRedeemVouchers = state.pendingRedeemVouchers.filter(voucher => voucher !== payload.code);
    },
    submitSuggestedVoucher(state, { payload }: PayloadAction<string>) {
      state.code = payload;
      state.loading = true;
      state.error = undefined;
      state.showDeletedVoucherHint= false;
    },
    clearVouchersSuggestions(state) {
      state.suggestions = undefined;
      state.redeemableVouchers = [];
      state.pendingRedeemVouchers = [];
      state.nonRedeemableVouchers = [];
      state.viewFailedVoucher = false;
    },
    setShowDeletedVoucherHint(state,{ payload }: PayloadAction<boolean>){
      state.showDeletedVoucherHint = payload;
    },
  },
});

export const {
  submitVoucher,
  submitVoucherSuccess,
  submitVoucherFailed,
  resetVoucher,
  resetVoucherState,
  changeVoucher,
  getVouchersSuggestions,
  verifyVoucher,
  verifyVoucherSuccess,
  addNonRedeemableVoucher,
  verifyVoucherFailure,
  submitSuggestedVoucher,
  getVouchersSuggestionsSuccess,
  clearVouchersSuggestions,
  submitSuggestedVoucherDone,
  getRedeemedVouchers,
  getRedeemedVouchersSuccess,
  setViewFailedVoucher,
  clearRedeemedVouchers,
  setShowDeletedVoucherHint,
  handleVoucherSession,
} = voucherSlice.actions;

export type VerifyVoucher = ReturnType<typeof verifyVoucher>;

export default voucherSlice.reducer;
