export default {
  'crossSellHints.ADT' : '„I recommend the popular additional product to you.”',

  'crossSellHints.ADT_ACTIV' : '„Our offer of the day is very popular today, why not order it at the same time.”',

  'crossSellHints.JDW' : '„My personal tip for the offer of the week.”',

  'crossSellHints.JDW_ACTIV' : '„Make sure you also get our coveted offer of the week.”',

  'crossSellHints.ERGANZEND' : '„As an ideal addition to your order, I have ...”',

  'crossSellHints.PERFEKT' : '„My personal recommendation is also "{brand} + {upSellName}".”',

  'crossSellHints.PREISREDUZIERUNG' : '„With this offer you will save "{reducedPriceEUR} {currencySymbol}" / "{reducedPricePERC} %".”',

  'crossSellHints.MARKE' : '„May I recommend our loyalty subscription with many advantages.”',

  'crossSellHints.SAISON' : '“Exklusiv für Sie, empfehle ich Ihnen unser 30-Jahre-Angebot.”',

  'crossSellHints.STUNDE' : '„Our limited offer this hour.”',

  'crossSellHints.SONDERANGEBOTE' : '„Many customers are enthusiastic about "{brand} + {upSellName}".”',

  'crossSellHints.UPGRADE_SET' : '„I recommend our advantage set, with which you save "{reducedPriceEUR} {currencySymbol}" / "{reducedPricePERC} %" today. This also includes your desired product.”',

  'crossSellHints.ADS_AKTIV' : '„My personal recommendation is the offer of the hour.”',

  'crossSellHints.PDM_AKTIV' : '„My personal tip is …”',

  'crossSellHints.PREMIERE' : '„My personal tip for the offer of the month …”',

  'crossSellHints.UPGRADE' : '„I recommend our special set where you save {DISCOUNT} today? It also includes {NAME}.“',
};
