import { PayloadAction } from '@reduxjs/toolkit';
import {
  call,
  getContext,
  put,
  takeLatest,
} from 'redux-saga/effects';

import { ApiCustomer } from 'src/api/apiCustomer';
import { logErrorEvent } from 'src/logging/loggingActions';
import { validateCustomerBirthdate } from 'src/redux/customer/CustomerPinSlice';
import { SagaContextItem } from 'src/store/ReduxSagaContext';
import { CustomerBirthdateCheckRequest } from 'src/types/customer/CustomerBirthdateCheckRequest';


function* checkCustomerBirthdateSaga(action: PayloadAction<CustomerBirthdateCheckRequest>) {
  try {
    const apiCustomer: ApiCustomer = yield getContext(SagaContextItem.apiCustomer);
    yield call(apiCustomer.checkCustomerBirthdate, action.payload);
  } catch (err) {
    yield put(
      logErrorEvent({
        message: `Couldn't check birthdate for customer '${action.payload.externalCustomerId}'`,
        err: err,
      }),
    );
  }
}

export default function* checkBirthdateWatcher() {
  yield takeLatest(validateCustomerBirthdate.type, checkCustomerBirthdateSaga);
}
