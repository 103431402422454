import dayjs from 'dayjs';
import {
  call,
  getContext,
  select,
  takeLatest,
} from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';

import { RootStateType } from 'src/constants/types';
import {
  logErrorEvent,
  logEvent,
  LogEventAction,
  LogEventProps,
} from 'src/logging/loggingActions';
import { LOG_LEVEL } from 'src/logging/loggingService';
import { SagaContextItem } from 'src/store/ReduxSagaContext';
import countryLocalStorage from 'src/utils/countryLocalStorage';
import { isNetworkError, isUnauthorizedStatus } from 'src/utils/errorStatusChecks';
import localCustomerStorage from 'src/utils/localCustomerStorage';
import Log from 'src/utils/log';
import stringify from 'src/utils/stringify';


export function* logSaga({ payload }: LogEventAction) {
  try {
    if(payload.err === undefined || isUnauthorizedStatus(payload.err) || skipTemporaryErrors(payload)) {
      return;
    }

    const apiLogging = yield getContext(SagaContextItem.apiLogging);

    // TODO: specify which state to log because logging-lambda log size is limited
    const appState = yield select((state: RootStateType) => {
      const localCustomerId = state.customer.info.selectedLocalCustomerId;
      const locaCustomer = localCustomerStorage.findLastNewCustomer(localCustomerId);
      return {
        orderEntry: { ...state.order.orderEntry, orderLineItems: undefined },
        payment: state.payment,
        customer: { ...state.customer.info.customerResponse, deliveryAddresses: undefined, pinVerification: state.customer.pinVerification },
        orderDisplay: state.order.orderDisplay,
        agentUsername: state.app.agentInfo?.username ?? '-',
        localCustomerData: {
          id: localCustomerId,
          consents: locaCustomer?.consents || '-',
          guestCustomerConsentsStepIsSkipped: locaCustomer?.guestCustomerConsentsStepIsSkipped || '-',
          wasAskedAboutConsents: locaCustomer?.wasAskedAboutConsents || '-',
        },
        args: payload.args,
      };
    });

    const correlationId = uuidv4();
    const requestBody = {
      ...payload,
      err_stack: payload.err?.stack ?? '-',
      correlationId,
      errMessage: `Error message: ${payload.err?.toString()}`,
      level: isNetworkError(payload.err) ? LOG_LEVEL.WARN : (payload.level ?? LOG_LEVEL.ERROR),
      state: appState,
      countryLocaleStorage: countryLocalStorage.get(),
      version: '1',
    };

    if(requestBody.level === LOG_LEVEL.ERROR) {
      requestBody.level = LOG_LEVEL.WARN;
      const pushToSlackResponse = yield call(apiLogging.pushToSlack, createSlackRequestBody(payload, correlationId));
      yield call(apiLogging.loggingService, { ...requestBody, pushed_to_slack: pushToSlackResponse });
    }
    else {
      yield call(apiLogging.loggingService, requestBody);
    }

  } catch (err) {
    yield call(Log.error, `Couldn't log the event ${payload}`);
  }
}

function skipTemporaryErrors(payload: LogEventProps) {
  return payload.err?.response?.data?.error === 'invalid_grant' || payload.message.includes('Could not fetch address suggestions');
}

function createSlackRequestBody(props: LogEventProps, correlationId: string) {
  let slackText;
  try {
    const cloudwatchUrl = awsCloudwatchUrl(correlationId);
    const cloudwatchUrlText = cloudwatchUrl ? `<${cloudwatchUrl}|Link To CloudWatch>` : '';
    const apiResponseError = props.err?.response?.data ? `API response: ${stringify(props.err.response.data).substr(0, 600)}...\n\n`: '';
    const stackTrace = props.err?.stack?.substr(0, 1000);
    slackText = `:warning: ${props.message} \n\`\`\`${apiResponseError}${stackTrace}...\`\`\` ${cloudwatchUrlText}`;
  } catch (err) {
    slackText = 'Error while logging error';
  }

  return (
    {
      'username': 'Wilis-View alarm',
      'blocks': [
        {
          'type': 'section',
          'text': {
            'type': 'mrkdwn',
            'text': slackText,
          },
        },
      ],
    }
  );
}

export function awsCloudwatchUrl(correlationId: string) {
  try {
    const now = dayjs();
    const start = now.subtract(1, 'h').format('YYYY-MM-DDTHH') + '*3a00*3a00';
    const end = now.add(1, 'h').format('YYYY-MM-DDTHH') + '*3a00*3a00';
    const logGroup = '*2faws*2flambda*2flogging-lambda-logginglambda0B79D37B-1OSJ986F9H69K';
    return 'https://eu-central-1.console.aws.amazon.com/cloudwatch/home?region=eu-central-1#logs-insights:queryDetail=~(end~\'' + end + '.000Z' + '~start~\'' + start + '.999Z' + '~timeType~\'ABSOLUTE~tz~\'Local~editorString~\'fields*20*40timestamp*2c*20message*0a*7c*20filter*20level*3d*22WARN*22*20AND*20correlation_id*3d*22'+correlationId+'*22*0a*7c*20sort*20*40timestamp*20desc~isLiveTail~false~queryId~\'16a1deeb-583e-40f7-88cb-46e5e7d7bfcd~source~(~\'' + logGroup + '))';
  } catch (err) {
    return undefined;
  }
}

export default function* loggingSagasWatcher() {
  yield takeLatest(logEvent.type, logSaga);
  yield takeLatest(logErrorEvent.type, logSaga);
}
