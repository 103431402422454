import { createAction, PayloadAction } from '@reduxjs/toolkit';
import {
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';

import { logErrorEvent } from 'src/logging/loggingActions';
import { customerInfoSelectors } from 'src/redux/customer/selectors/customerInfoSelectors';
import localCustomerStorage, { SavedCustomerConsents } from 'src/utils/localCustomerStorage';


export function* storeCustomerLocally(action: PayloadAction<object>) {
  try {
    const localCustomerId = yield select(customerInfoSelectors.getSelectedCustomerLocalId);
    if (action.type === storeCustomerConsents.type) {
      localCustomerStorage.setCustomerConsents(localCustomerId, action.payload);
    } else {
      throw new Error('action is not handled');
    }
  } catch (err) {
    yield put(logErrorEvent({ message: `could not ${action.type} data locally`, err }));
  }
}

export const storeCustomerConsents = createAction<SavedCustomerConsents>('customer/STORE_CONSENTS');

export default function* storeCustomerLocallyWatcher() {
  yield takeLatest(storeCustomerConsents.type, storeCustomerLocally);
}
