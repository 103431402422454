import { string } from 'yup';

import {
  PHONE_NUMBER_REGEX,
  phoneNumberSchema,
  zipCodeSchema,
  CRM_EMAIL_REGEX,
} from './yupSchema';


export function isSalutationValid(salutation: string): boolean {
  return string().required().isValidSync(salutation);
}

export function isPhoneValid(phone: string): boolean {
  return phoneNumberSchema().isValidSync(phone);
}

export function isNameValid(name: string) {
  return string()
    .min(2)
    .required()
    .isValidSync(name);
}

export function isZipCodeValid(zipCode: string) {
  return zipCodeSchema().required().isValidSync(zipCode);
}

export function isValueDefined(value: string) {
  return string().required().isValidSync(value);
}

export const validateEmail = (email: string) => {
  return CRM_EMAIL_REGEX.test(email);
};
export const validatePhone = (phone: string) => {
  return PHONE_NUMBER_REGEX.test(phone);
};
