import { AxiosInstance } from 'axios';
import { decamelizeKeys } from 'humps';

import { AddressValidationRequest } from 'src/types/customer/AddressValidationRequest';
import { AddressValidationResponse } from 'src/types/customer/AddressValidationResponse';
import { VerifyDhlPostNumberResponse } from 'src/types/customer/dhlPostNumberResponse';
import { DhlPostNumberRequest } from 'src/types/customer/dhPostNumberRequest';

import { getHttpClient } from './httpClient/HttpClient';
import { refreshTokenInterceptor } from './httpClient/interceptors/refreshTokenInterceptor';


export interface ApiAddress {
  client: AxiosInstance;
  validateAddress: (address: AddressValidationRequest) => Promise<AddressValidationResponse>;
  verifyDhlPostNumber: (payload: DhlPostNumberRequest) => Promise<VerifyDhlPostNumberResponse>;
}

export function getApiAddress(baseURL: string): ApiAddress {
  const addressclient = getHttpClient({
    baseUrl: baseURL,
    transform: {
      camelize: true,
    },
    interceptors: {
      response: refreshTokenInterceptor,
    },
  });

  return {
    client: addressclient,
    validateAddress: async function (address: AddressValidationRequest) {
      const response = await addressclient.post<AddressValidationResponse>(
        'address-check-and-suggestions',
        decamelizeKeys(address)
      );
      return response.data;
    },
    verifyDhlPostNumber: async function (payload: DhlPostNumberRequest) {
      const response = await addressclient.post('/verify-post-number', decamelizeKeys(payload));

      return response.data;
    },
  };
}
